import { computed, ref } from 'vue';

import { defineStore, storeToRefs } from 'pinia';

import { PatientsResponse } from '@/libs/Patients/http/PatientsResponse.interface';
import { usePatientsStore } from '@/libs/Patients/usePatientsStore';
import { useUserInfoStore } from '@/libs/User/useUserInfoStore';

import { useResetOnLogout } from '../Authorization/use/useResetOnLogout';
import { LoyalityInfoResponse } from './type/LoyalityInfoResponse';
import { useLoyalityApi } from './use/useLoyalityApi';

type UserId = PatientsResponse['userId'];

export type LoyalityState = Record<
  UserId,
  {
    isLoading: boolean;
    bonuses: number;
    enabled: boolean;
    canJoin: boolean;
  }
>;

export const useLoyalityStore = defineStore('useLoyalityStore', () => {
  const loyalityService = useLoyalityApi();

  const patientsStore = usePatientsStore();
  const { activePatient } = storeToRefs(patientsStore);

  const userInfoStore = useUserInfoStore();
  const { info: userInfo } = storeToRefs(userInfoStore);

  const state = ref<LoyalityState>({});

  const reset = () => {
    state.value = {};
  };

  useResetOnLogout(reset);

  const userId = computed(() => {
    return activePatient.value?.userId ?? userInfo.value?.userId ?? -1;
  });

  const bonusesActivePatient = computed(() => {
    const id = userId.value;
    const val = state.value;

    const value = val[id] || { bonuses: 0 };

    return typeof value.bonuses === 'number' ? value.bonuses : null;
  });

  const enabledActivePatient = computed(() => {
    const id = userId.value;
    const enabled = state.value[id]?.enabled;

    return typeof enabled === 'boolean' ? enabled : null;
  });

  const canJoinActivePatient = computed(() => {
    const id = userId.value;
    const value = state.value[id];

    return !enabledActivePatient.value && !!value?.canJoin;
  });

  const isLoadingActivePatient = computed(() => {
    const id = userId.value;
    const loading = state.value[id]?.isLoading;

    return typeof loading === 'boolean' ? loading : true;
  });

  const setIsLoading = (value: { userId: UserId; value: boolean }) => {
    const _state = state.value;

    state.value = {
      ..._state,
      [value.userId]: {
        ...(_state[value.userId] || {}),
        isLoading: value.value,
      },
    };
  };

  const setValue = (payload: {
    userId?: UserId;
    loyality: LoyalityInfoResponse | null;
  }) => {
    const { loyality, userId } = payload;

    if (!userId) {
      return;
    }

    const value = {
      bonuses: loyality?.bonusCardBalanceValue || 0,
      enabled: loyality?.userParticipatesInLoyality || false,
      canJoin: loyality?.userCanJoinInLoyality || false,
    };

    const _state = state.value;

    state.value = {
      ..._state,
      [userId]: {
        ...(_state[userId] || { isLoading: false }),
        ...value,
      },
    };
  };

  const loadFor = async (payload: { userId: UserId; force?: boolean }) => {
    const _state = state.value;

    if (_state[payload.userId] !== undefined && !payload.force) {
      return;
    }

    if (!payload.userId) {
      return;
    }

    setIsLoading({ userId: payload.userId, value: true });

    const response = await loyalityService
      .getInfoFor(payload.userId)
      .catch(() => ({
        userParticipatesInLoyality: false,
        userCanJoinInLoyality: false,
        bonusCardBalance: null,
        bonusCardBalanceValue: null,
      }));

    setValue({ userId: payload.userId, loyality: response });
    setIsLoading({ userId: payload.userId, value: false });
  };

  return {
    loadFor,

    state,

    bonusesActivePatient,
    enabledActivePatient,
    canJoinActivePatient,
    isLoadingActivePatient,
  };
});
