import { InterceptorFn } from '@/libs/Http/setupInterceptors';

import { CustomAxiosRequestConfig } from './CustomAxiosRequestConfig.interface';

const getUrl = (url: string, isSmartmed = false) => {
  const baseUrl = isSmartmed
    ? GLOBAL_CONFIG.BASE_URL_SMARTMED
    : GLOBAL_CONFIG.BASE_URL_PROXY;

  return url.startsWith('/') ? `${baseUrl}${url}` : url;
};

export function urlByConfigInterceptor(): InterceptorFn {
  return (interceptors) => {
    interceptors.request.use((config: CustomAxiosRequestConfig) => {
      config.url = getUrl(config.url ?? '', config.isSmartmed);

      return config;
    });
  };
}
