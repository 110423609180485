export enum Feature {
  All = 'smartmed', // Блокировка работы всего приложения
  Analysis = 'lab', // Лабораторный супермаркет
  Conferences = 'conferences', // Запись на все консультации
  OfflineConsultation = 'offlineconf', // Запись на оффлайн консультации
  OnlineConsultation = 'onlineconf', // Запись на онлайн консультации
  Instrumental = 'instrumental', // Инструментальные исследования
  NewOfflineConsultation = 'new_offlineconf', // Новая запись в клинику
  NewOnlineConsultation = 'new_onlineconf', // Новая онлайн консультация
  NewConferences = 'new_conferences', // Запись на все новые консультации
  PrintReception = 'print_reception', // Печать страницы в описании записи
  OfflineReceptionPayment = 'offline_reception_payment', // Оплата приёма
  MakeDeposit = 'make_deposit', // Пополненине депозита
  SendEmail = 'sendemail', // Отправка файлов на почту
  ChatScript = 'chat_script', // Загрузка скрипта чата

  ReferralDoctorBooking = 'referral_doctor_booking', // запись из направления
  ReferralExplorationBooking = 'referral_exploration_booking',
  ReferralLaboratoryBooking = 'referral_laboratory_booking',
  ReferralProgramBooking = 'referral_program_booking',

  NewReferrals = 'is_new_referrals', // новые направления в медкарте

  Chronics = 'chronics_health_diary',
}

export const FeaturesGraph: Record<Feature, ReadonlyArray<Feature>> = {
  [Feature.All]: [],
  [Feature.Analysis]: [Feature.All],
  [Feature.Instrumental]: [Feature.All],

  [Feature.Conferences]: [Feature.All],
  [Feature.OfflineConsultation]: [Feature.Conferences],
  [Feature.OnlineConsultation]: [Feature.Conferences],

  [Feature.NewConferences]: [Feature.All],
  [Feature.NewOfflineConsultation]: [Feature.NewConferences],
  [Feature.NewOnlineConsultation]: [Feature.NewConferences],

  [Feature.PrintReception]: [Feature.All],
  [Feature.OfflineReceptionPayment]: [Feature.All],

  [Feature.MakeDeposit]: [Feature.All],
  [Feature.SendEmail]: [Feature.All],

  [Feature.ChatScript]: [Feature.All],

  [Feature.NewReferrals]: [Feature.All],

  [Feature.ReferralDoctorBooking]: [Feature.NewReferrals],
  [Feature.ReferralExplorationBooking]: [Feature.NewReferrals],
  [Feature.ReferralLaboratoryBooking]: [Feature.NewReferrals],
  [Feature.ReferralProgramBooking]: [Feature.NewReferrals],
  [Feature.Chronics]: [Feature.All],
};
