import { MediaValue } from './mediaValue.enum';

const BOOTSTRAP_CORRECTION = 0.2;

export const mediaOptions = {
  [MediaValue.XXS]: 320 - BOOTSTRAP_CORRECTION,
  [MediaValue.XS]: 576 - BOOTSTRAP_CORRECTION,
  [MediaValue.SM]: 768 - BOOTSTRAP_CORRECTION,
  [MediaValue.MD]: 1024 - BOOTSTRAP_CORRECTION,
  [MediaValue.LG]: 1280 - BOOTSTRAP_CORRECTION,
  [MediaValue.XL]: 1440 - BOOTSTRAP_CORRECTION,
} as const;
