import { errorCodes } from './errorCodes';
import { mapOfResponseMessages } from './mapOfResponseMessages';

export type ServerMessage = {
  code: string;
  descr: string;
  paramName: string | null;
};

export type Message = {
  text: string | null;
  field?: string | null;
};

export type ServerPayload<D> = {
  data: null | D;
  statusInfo: {
    infoMessages: Array<ServerMessage>;
    status: string;
  };
  message?: string;
};

export type Messages = {
  byFields: {
    [key: string]: Message;
  };
  common: Array<string>;
};

export class Response<D> {
  readonly payload: {
    data: null | D;
    messages: Messages;
  };

  readonly status: number;
  readonly success: boolean;

  constructor({
    payload,
    status,
    success,
    isMapMessages = false,
  }: {
    payload: ServerPayload<D>;
    status: number;
    success: boolean;
    isMapMessages?: boolean;
  }) {
    try {
      let responseSuccess = success;
      let messages = [...payload.statusInfo.infoMessages];

      if (isMapMessages) {
        messages = this.mapMessages(messages);
      }

      if (payload.statusInfo.status === errorCodes.ErrorStatus) {
        responseSuccess = false;
      }

      const { byFields, common } = this.format(messages);

      this.payload = {
        data: payload.data,
        messages: {
          byFields,
          common,
        },
      };
      this.status = status;
      this.success = responseSuccess;
    } catch {
      this.payload = {
        data: typeof payload === 'string' ? payload : payload.data || null,
        messages: {
          byFields: {},
          common: (payload.message && [payload.message]) || [],
        },
      };
      this.status = status;
      this.success = success;
    }
  }

  // TODO: needs refactoring - needs to be moved to the own class
  private format(messages: Array<ServerMessage>): Messages {
    return messages.reduce(
      (accum: Messages, message) => {
        if (message.paramName) {
          accum.byFields[message.paramName] = {
            field: message.paramName,
            text: message.descr,
          };
        } else if (message.descr) {
          accum.common.push(message.descr);
        }

        return accum;
      },
      { byFields: {}, common: [] }
    );
  }

  private mapMessages(messages: Array<ServerMessage>) {
    return messages.map((message) => {
      const newMessage = mapOfResponseMessages[message.code];

      if (newMessage) {
        message.descr = newMessage.descr || message.descr;
        message.paramName = newMessage.paramName || message.paramName;
      }

      return message;
    });
  }
}
