import { computed, ref } from 'vue';

import { defineStore } from 'pinia';

import { cityService } from '@/libs/User/http/City/City.service';
import { userLocationService } from '@/libs/User/UserLocation.service';

import { useResetOnLogout } from '../Authorization/use/useResetOnLogout';
import { City } from './City.types';

const CITIES_START_PAGE = 1;
const CITIES_SIZE = 50;

const defaultLocation = {
  latitude: undefined,
  longitude: undefined,
};

export const useCityStore = defineStore('useCityStore', () => {
  const city = ref<City | null>(null);
  const cities = ref<City[] | null>(null);
  const isLoading = ref<boolean>(false);
  const isConfirmed = ref<boolean>(false);
  const isInitialized = ref<boolean>(false);

  const reset = () => {
    city.value = null;
    cities.value = null;
    isLoading.value = false;
    isConfirmed.value = false;
    isInitialized.value = false;
  };

  useResetOnLogout(reset);

  const cityName = computed(() => {
    const value = city.value;

    return value ? value.name : 'Москва и МО';
  });

  const loadUserCity = async () => {
    if (isConfirmed.value || isLoading.value || isInitialized.value) {
      return;
    }

    isInitialized.value = true;
    isLoading.value = true;

    const location = userLocationService.getLocation() || defaultLocation;

    const response = await cityService
      .getUserCity(location.latitude, location.longitude)
      .catch(() => null);

    if (response && (response.userLocation || response.nearestCity)) {
      const resp = response.userLocation.isUserLocation
        ? response.userLocation.city!
        : response.nearestCity!;

      setCity(resp);

      isConfirmed.value = response.userLocation.isUserLocation;
    } else {
      isInitialized.value = false;
    }

    isLoading.value = false;
  };

  const setCity = (value: City | null) => {
    isInitialized.value = !!value;
    isConfirmed.value = !!value;
    city.value = value;
  };

  const loadCities = async () => {
    if (cities.value) {
      return;
    }

    isLoading.value = true;

    const response = await cityService
      .getCities({
        page: CITIES_START_PAGE,
        size: CITIES_SIZE,
      })
      .catch(() => null);

    const items = response?.items ?? null;

    cities.value = items;
    isLoading.value = false;
  };

  const setIsConfirmed = (value: boolean) => {
    isConfirmed.value = value;
  };

  return {
    city,
    cities,
    isLoading,
    isConfirmed,
    isInitialized,

    cityName,

    loadUserCity,
    setCity,
    loadCities,
    setIsConfirmed,
  };
});
