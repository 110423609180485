import { RouteRecordRaw } from 'vue-router';

import { notificationsRoutes } from '../Notifications/route';
import { familyRoutes } from './views/Family/route';
import { loyalityRoutes } from './views/Loyality/route';
import { myProfileRoutes } from './views/MyProfile/route';
import { promocodesRoutes } from './views/Promocodes/route';
import { walletRoutes } from './views/Wallet/route';

const children = ([] as ReadonlyArray<RouteRecordRaw>)
  .concat(myProfileRoutes)
  .concat(familyRoutes)
  .concat(promocodesRoutes)
  .concat(walletRoutes)
  .concat(loyalityRoutes)
  .concat(notificationsRoutes);

export const profileRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: '/profile',
    redirect: `/profile/${children[0].path}`,
    component: () => import('./views/ProfileRoot.vue'),
    children,
  },
];
