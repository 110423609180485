export const PHRASES = {
  required: 'Обязательно к заполнению',
  mustBeEqualEleven: 'Должен содержать 11 цифр',
  chooseSex: 'Укажите пол',
  readUserAgreement: 'Ознакомьтесь с пользовательским соглашением',
  passwordMustNotBeLessThenEight: 'Пароль должен быть не меньше 8 символов',
  ageMustBeFromEighteen: 'Возраст должен быть не меньше 18 лет',
  enterCorrectEmail: 'Введите корректный адрес электронной почты',
  phoneIsNotCorrect: 'Некорректный номер мобильного',
  invalidDate: 'Недопустимая дата',
  confirmTextToDeleteTag: 'Вы уверены, что хотите удалить тэг',
  confirmTextToDeleteDocument: 'Вы уверены, что хотите удалить документ',
  errorTextDefault: 'Что-то пошло не так, попробуйте позже',
  errorLoadDocument: 'Произошла ошибка при загрузке документа или нет доступа',
  errorFieldEmpty: 'Обязательно к заполнению',
  errorEmail: 'Неверно указан email',
  copyTextToBuffer: 'Скопировано в буфер обмена',
  errorLoadDoctor:
    'Произошла ошибка при загрузке информации о враче. Попробуйте позже',
  sucсessSaveProfile:
    'Почти готово! Обработка ваших данных займёт некоторое время. Мы свяжемся с вами при необходимости',
  errorLoadFile: 'Произошла ошибка при выгрузки файла',
};
