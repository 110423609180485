import { httpClient } from '@/libs/Http';
import { pythonDtoMapper } from '@/libs/Http/pythonDtoMapper';
import { SmartmedListItems } from '@/shared/Types/Smartmed/ListItems.type';
import { Paging } from '@/shared/Types/Smartmed/Paging.type';

import {
  DTOCity,
  DTOCityUserResponse,
} from '../../types/City/CityUserResponse';

export class CityRepository {
  getUserCity(latitude?: number, longitude?: number) {
    return httpClient
      .get<DTOCityUserResponse>('/clinics/clinics/v1/cities/city/user', {
        params: {
          latitude,
          longitude,
          with_clinic: true,
        },
        isSmartmed: true,
      })
      .then(pythonDtoMapper);
  }

  getCities(pagination: Paging, name?: string) {
    return httpClient
      .get<SmartmedListItems<DTOCity>>('/clinics/clinics/v1/cities/', {
        params: {
          ...pagination,
          with_clinic: true,
          name,
          context: 'reception',
        },
        isSmartmed: true,
      })
      .then(pythonDtoMapper);
  }
}
