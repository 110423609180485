import { watch } from 'vue';

import { storeToRefs } from 'pinia';

import { PatientsResponse } from '@/libs/Patients/http/PatientsResponse.interface';
import { usePatientsStore } from '@/libs/Patients/usePatientsStore';
import { UserInfoType } from '@/libs/User/types/Info/UserInfo.type';
import { useUserInfoStore } from '@/libs/User/useUserInfoStore';

import { useLoyalityStore } from '../useLoyalityStore';

export function useLoyalityInitialization() {
  const loyalityStore = useLoyalityStore();

  const patientsStore = usePatientsStore();
  const { activePatient } = storeToRefs(patientsStore);

  const userInfoStore = useUserInfoStore();
  const { info: user } = storeToRefs(userInfoStore);

  const initialize = () => {
    if (user.value) {
      loyalityStore.loadFor({ userId: user.value.userId });
    }
  };

  const loadForActivePatient = ([patient, user]: [
    PatientsResponse | null,
    UserInfoType | null
  ]) => {
    if (!patient) {
      if (user) {
        loyalityStore.loadFor({ userId: user.userId });
      }

      return;
    }

    loyalityStore.loadFor({ userId: patient.userId });
  };

  watch([activePatient, user], loadForActivePatient, {
    immediate: true,
  });

  return {
    initialize,
  };
}
