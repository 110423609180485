export const errorCodes = {
  ErrorStatus: 'ERROR',
  SuccessStatus: 'SUCCESS',
  UserCreatedEarlier: 'USER_CREATED_EARLIER',
  UserNotRegistered: 'USER_NOT_REGISTERED',
  PatientOtherClientLinked: 'PATIENT_OTHER_CLIENT_LINKED',
  UserAlreadyRegisteredAsClient: 'USER_ALREADY_REGISTERED_AS_CLIENT',
  TooMuchPatientsOnClient: 'TOO_MUCH_PATIENTS_ON_CLIENT',

  AuthError: 'AUTH_ERROR',
  SmsCodeRequested: 'SMS_CODE_REQUESTED',
  SmsCodeIncorrect: 'SMS_CODE_INCORRECT',
  SmsCodeTooMany: 'SMS_CODE_TOO_MANY',
  NoRightsForPatientEditing: 'NO_RIGHTS_FOR_PATIENT_EDITING',
  NoRightsForConference: 'NO_RIGHTS_FOR_CONFERENCE',
  ConsultationIsFree: 'CONSULTATION_IS_FREE',
  ConsultationHasInvalidStatus: 'CONSULTATION_HAS_INVALID_STATUS',

  FormatError: 'FORMAT_ERROR',
  EmptyDataError: 'EMPTY_DATA_ERROR',
  InvalidValueError: 'INVALID_VALUE_ERROR',
  EmptyProfileParameterError: 'EMPTY_PROFILE_PARAMETER',
  PaymentSystemError: 'PAYMENT_SYSTEM_ERROR',

  SystemError: 'SYSTEM_ERROR',

  Error: 'SYSTEM_ERROR',

  ApplicationVersionError: 'APPLICATION_VERSION_TOO_SMALL_ERROR',

  ConfAlreadyInQueue: 'CONF_ALREADY_IN_QUEUE',

  NotSupportedFileExtension: 'NOT_SUPPORTED_FILE_EXTENSION',

  StuffUnitNotFound: 'STUFF_UNIT_NOT_FOUND',

  ScheduleNotFound: 'SCHEDULE_NOT_FOUND',

  ClinicNotSupport: 'CLINIC_NOT_SUPPORT',

  InvalidScheduleDateTime: 'INVALID_SCHEDULE_DATETIME',

  DoctorsOutOfWork: 'DOCTORS_OUT_OF_WORK',

  RefreshTokenError: 'REFRESH_TOKEN_ERROR',
  SessionTokenError: 'SESSION_TOKEN_ERROR',

  AccessDenyError: 'ACCESS_DENY',

  ScoreDescriptionNotFound: 'SCORE_DESCRIPTION_NOT_FOUND',

  PromotionContractActivateTimeout: 'PROMOTION_CONTRACT_ACTIVATE_TIMEOUT',

  NonClosedAppealExists: 'NON_CLOSED_APPEAL_EXISTS',
  MaxAppealsReached: 'MAX_APPEALS_REACHED',

  SuccessNonZeroConf: 'SUCCESS_NON_ZERO_CONFERENCES',
};
