import { RouteRecordRaw } from 'vue-router';

export const helpRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: '/help',
    name: 'HelpRoot',
    component: () => import('@/libs/Layouts/SimpleLayout.vue'),
    meta: {
      tabTitle: 'Частые вопросы',
    },
    children: [
      {
        path: '',
        redirect: { name: 'Faq' },
        name: 'Help',
        component: () => import(`./views/Help.vue`),
        meta: {
          title: 'Помощь',
        },
        children: [],
      },
      {
        path: 'faq',
        name: 'Faq',
        component: () => import(`./views/Help.vue`),
        meta: {
          title: 'Помощь',
        },
      },
      {
        path: 'faq/category/:id',
        name: 'CategoryFaq',
        component: () => import(`./views/Faq/views/CategoryFaq.vue`),
        meta: {
          title: 'Помощь',
        },
      },
      {
        path: 'faq/question/:id',
        name: 'QuestionFaq',
        props: (route) => ({ id: Number(route.params.id) }),
        component: () => import(`./views/Faq/views/QuestionFaq.vue`),
        meta: {
          title: 'Помощь',
        },
      },
    ],
  },
];
