import { Router } from 'vue-router';

import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { errorCodes } from '@/libs/Http/errorCodes';
import { ServerPayload } from '@/libs/Http/Response';
import { InterceptorFn } from '@/libs/Http/setupInterceptors';

import { accessTokenStorage } from '../use/useAuthorization';

function handleResponse(
  routerGetter: () => Router
): (response: AxiosResponse<unknown>) => Promise<unknown> {
  return (response: AxiosResponse<any>) => {
    const payload = response.data;
    const isError =
      payload.statusInfo &&
      payload.statusInfo.status === errorCodes.ErrorStatus;

    if (!isError) {
      return Promise.resolve(response);
    }

    const messages = (payload as ServerPayload<any>).statusInfo.infoMessages;
    const isTokenExpired =
      !!messages &&
      messages.find(({ code }) => code === errorCodes.SessionTokenError);

    if (!isTokenExpired) {
      return Promise.resolve(response);
    }

    const router = routerGetter();

    const deleteTokens = new Promise((resolve) => {
      accessTokenStorage.delete();

      resolve(undefined);
    });

    return deleteTokens.then(() => {
      const currentRoute = router.currentRoute.value;

      if (!currentRoute.meta.allowUnauth) {
        return router
          .push({
            name: 'SignIn',
            query: {
              from: encodeURIComponent(currentRoute.fullPath),
            },
          })
          .then(() => response);
      }
    });
  };
}

export function authTokenInterceptor(
  routerGetter: () => Router
): InterceptorFn {
  return (interceptors) => {
    interceptors.request.use((config: AxiosRequestConfig) => {
      const token = accessTokenStorage.get();
      const headers = config.headers || {};

      if (token && !headers.Authorization) {
        config.headers = {
          ...headers,
          Authorization: token,
        };
      }

      return config;
    });

    const handler = handleResponse(routerGetter);

    interceptors.response.use(handler, (error) => {
      if (!error.response || !error?.response?.statusInfo) {
        return Promise.reject(error);
      }

      return handler(error.response);
    });
  };
}
