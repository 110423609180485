import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';

import { authzoneRoutes, unauthzoneRoutes } from '@/application/route';
import { authzoneGuard } from '@/libs/Authorization/guards/Authzone/authzoneGuard';
import { medsiSmartmedTransitionGuard } from '@/libs/Http/medsiSmartmedTransition.guard';
import { prefixTabTitle } from '@/shared/BrowserApi/DocumentTitle/prefixTabTitle';

const routes = [
  ...unauthzoneRoutes,
  {
    path: '/',
    component: () => import('@/libs/Layouts/LayoutDefault.vue'),
    beforeEnter: medsiSmartmedTransitionGuard,
    children: authzoneRoutes,
  },
];

const router = createRouter({
  history: createWebHistory(GLOBAL_CONFIG.BASE_HREF),
  routes,
});

router.beforeEach(
  (
    to: RouteLocationNormalized,
    _: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    if (!to.meta.allowUnauth) {
      const isAuthzone = authzoneGuard();

      if (isAuthzone) {
        // TODO: throw it out when ios app is deployed
        const hasPatientPrefix = to.fullPath.includes('/patient');
        const path = hasPatientPrefix
          ? to.fullPath.replace('/patient', '')
          : null;

        if (path) {
          next(path);
        } else {
          next();
        }
      } else {
        next({
          name: 'SignIn',
          query: {
            from: encodeURIComponent(to.fullPath.replace('access_token', '_')),
            access_token: to.query.access_token || undefined,
          },
        });
      }
    } else {
      next();
    }
  }
);

router.afterEach((to: RouteLocationNormalized) => {
  if (to.meta.tabTitle) {
    document.title = `${prefixTabTitle ?? ''} ${to.meta.tabTitle as string}`;
  }

  if (!to.meta.tabTitle && to.meta.title) {
    document.title = to.meta.title as string;
  }
});

router.afterEach((to: RouteLocationNormalized) => {
  if (to.meta.savedPosition) {
    return;
  }

  window.scrollTo({ top: 0 });
});

const baseHref = GLOBAL_CONFIG.BASE_HREF || '';

router.onError((error, to) => {
  const dynamicallyImportsError =
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed');

  if (error.name === 'ChunkLoadError' || dynamicallyImportsError) {
    location.href = `${baseHref}${to.fullPath}`;
  }
});

export default router;
