import { AddPatientRequest } from '@/libs/Patients/types/AddPatientRequest.type';
import { getISOWithoutTimezone } from '@/shared/Utility/Date/getISOWithoutTimezone';
import { getFirstChar } from '@/shared/Utility/String/getFirstChar';

import { AddPatientResponse } from '../types/AddPatientResponse.type';
import { PatientType } from '../types/Patient.type';
import { PatientsRepository } from './Patients.repository';
import { PatientsResponse } from './PatientsResponse.interface';

export class PatientsService {
  constructor(private patients: PatientsRepository) {}

  async get(): Promise<Array<PatientsResponse>> {
    const { payload } = await this.patients.get();

    if (!payload.data) {
      return [];
    }

    return this.getFlatPatientsWithFullName(payload.data);
  }

  delete(userId: number): Promise<null> {
    return this.patients
      .delete(userId)
      .then((response) => response.payload.data);
  }

  add(request: AddPatientRequest): Promise<AddPatientResponse | null> {
    return this.patients
      .add(request)
      .then((response) =>
        response.success
          ? response.payload.data
          : Promise.reject(response.payload.messages)
      );
  }

  private getFlatPatientsWithFullName(
    patients: Array<PatientType>
  ): Array<PatientsResponse> {
    return patients.map((patient: PatientType) => {
      return {
        ...patient.userDetails,
        isDefault: patient.isDefault,
        fullName: `${patient.userDetails.firstName} ${patient.userDetails.lastName}`,
        firstCharOfName: getFirstChar(patient.userDetails.firstName),
        birthday: new Date(getISOWithoutTimezone(patient.userDetails.birthday)),
      };
    });
  }
}

export const patientsService = new PatientsService(new PatientsRepository());
