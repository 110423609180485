import { RouteRecordRaw } from 'vue-router';

import { guardsBuilder } from '@/libs/Http/guardsBuilder';

import { appointmentDoctorsGuard } from './guards/appointmentDoctorsGuard';
import { appointmentSubmitGuard } from './guards/appointmentSubmitGuard';

export const appointmentRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: '/appointment',
    name: 'AppointmentRoot',
    component: () => import('./views/AppointmentRoot.vue'),
    meta: {
      title: 'Запись в клинику',
      tabTitle: 'Запись на приём в клинику',
    },
    children: [
      {
        path: '',
        name: 'Appointment',
        component: () => import('./views/Appointment.vue'),
        meta: {
          title: '',
        },
      },
      {
        path: 'old',
        name: 'OldAppointment',
        component: () => import('./views/OldAppointment.vue'),
        meta: {
          title: 'Запись в клинику',
        },
      },
      {
        path: 'confirm',
        name: 'AppointmentConfirm',
        component: () => import('./views/Confirm.vue'),
        meta: {
          title: '',
        },
      },
      {
        path: 'tmp',
        name: 'MedsiAppointment',
        component: () => import('./views/MedsiAppointment.vue'),
        props: (route) => ({
          clinicCode: route.query.clinicCode,
          examCode: route.query.examCode,
        }),
      },
      {
        path: 'doctors',
        name: 'AppointmentDoctors',
        beforeEnter: guardsBuilder([appointmentDoctorsGuard], '/appointment'),
        component: () => import('./views/AppointmentDoctors.vue'),
        meta: {
          title: 'Запись в клинику',
        },
      },
      {
        path: 'submit',
        name: 'AppointmentSubmit',
        beforeEnter: guardsBuilder(
          [appointmentDoctorsGuard, appointmentSubmitGuard],
          '/appointment'
        ),
        component: () => import('./views/AppointmentSubmit.vue'),
        meta: {
          title: 'Запись в клинику',
        },
      },
      {
        path: 'passport',
        name: 'AppointmentPassport',
        component: () => import('./views/Passport.vue'),
        meta: {
          title: '',
        },
      },
    ],
  },
];
