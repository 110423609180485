import { httpClient } from '@/libs/Http';
import { Response } from '@/libs/Http/Response';
import { AddPatientRequest } from '@/libs/Patients/types/AddPatientRequest.type';

import { AddPatientResponse } from '../types/AddPatientResponse.type';
import { PatientType } from '../types/Patient.type';

export class PatientsRepository {
  async get(): Promise<Response<Array<PatientType>>> {
    return new Response(await httpClient.get('/users/getpatients'));
  }

  async add(request: AddPatientRequest): Promise<Response<AddPatientResponse>> {
    return httpClient
      .post<AddPatientResponse, AddPatientRequest>(
        '/users/addpatient/v2',
        request
      )
      .then((response) => new Response(response));
  }

  async delete(userId: number): Promise<Response<null>> {
    return httpClient
      .post<null, null>('/users/removepatient', null, {
        headers: {
          PatientId: userId,
        },
      })
      .then((response) => {
        const mappedResponse = new Response<null>(response);

        return mappedResponse.success
          ? mappedResponse
          : Promise.reject(response);
      });
  }
}
