import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { RoomMessageStatus } from '@patient/addon-chat';
import { useAlerts } from '@smartmed/ui/use/alerts';
import { defineStore } from 'pinia';

import { useResetOnLogout } from '../Authorization/use/useResetOnLogout';
import { DTOChatMessageType } from './dto/chat.message';
import { DTOChatFileData } from './dto/content.message';
import { DTOChatMessage } from './dto/message.dto';
import { DTOChatState, useChatApi } from './useChatApi';

export type ChatId = string;

type MessageStatuses = Record<string, RoomMessageStatus>;

type State = Record<
  ChatId,
  | { loading: true }
  | {
      loading: false;
      loadingMore: boolean;
      allowMore: boolean;
      status: DTOChatState;
      messages: DTOChatMessage[];
    }
>;

const CHAT_MESSAGES_CHUNK = 20;

export const useChronicsChatStore = defineStore('useChronicsChatStore', () => {
  const chatApi = useChatApi();
  const alertsService = useAlerts();
  const router = useRouter();

  const state = ref<State>({});
  const lastNewMessageInChatId = ref<Record<ChatId, number>>({});
  const messageStatusesState = ref<MessageStatuses>({});
  const seedToMessageId = ref<Record<string | number, string>>({});
  const messageIdToBlobSrc = ref<Record<string, string | undefined>>({});
  const alert = ref('');

  const reset = () => {
    state.value = {};
    lastNewMessageInChatId.value = {};
    messageStatusesState.value = {};
    seedToMessageId.value = {};
    messageIdToBlobSrc.value = {};
    alert.value = '';
  };

  useResetOnLogout(reset);

  const loadChatHistory = (payload: { id: ChatId; lastMessageId: string }) => {
    return chatApi
      .getChatHistory(payload.id, CHAT_MESSAGES_CHUNK, payload.lastMessageId)
      .then((payload) =>
        payload ? Promise.resolve(payload) : Promise.reject(null)
      );
  };

  const safeLoadChat = (payload: { id: ChatId; force?: boolean }) => {
    const _state = state.value;

    if (!payload.force && _state[payload.id]) {
      return Promise.resolve(null);
    }

    const stateMutationPayload = {
      ..._state,
      [payload.id]: {
        loading: true as const,
      },
    };

    state.value = stateMutationPayload;

    return chatApi.getChatInfo(payload.id).then((response) => {
      if (response) {
        return chatApi
          .getChatHistory(
            payload.id,
            CHAT_MESSAGES_CHUNK,
            response.lastMessageId
          )
          .then((messages: DTOChatMessage[] | null) => {
            const stateMutationPayload = {
              ...state.value,
              [payload.id]: {
                loading: false,
                loadingMore: false,
                allowMore: (messages || []).length === CHAT_MESSAGES_CHUNK,
                status: response.chatState,
                messages: messages || [],
              },
            };

            state.value = stateMutationPayload;
          });
      }

      return null;
    });
  };

  const showNewMessageAlert = () => {
    alertsService.show('У вас новое сообщение от доктора');
  };

  const markMessage = (payload: {
    messageId: string;
    status: RoomMessageStatus;
  }) => {
    messageStatusesState.value = {
      ...messageStatusesState.value,
      [payload.messageId]: payload.status,
    };
  };

  const setLastNewMessageInChatId = (payload: ChatId) => {
    lastNewMessageInChatId.value = {
      ...lastNewMessageInChatId.value,
      [payload]: Date.now(),
    };
  };

  const loadHistoryAfterNewMessage = (payload: {
    chatId: ChatId;
    messageId: string;
    seed: number;
  }) => {
    const _state = state.value;

    const currentState = _state[payload.chatId];
    const currentRoute = router.currentRoute.value;

    const isConsultationOpened = currentRoute.name === 'ChronicsChat';

    setLastNewMessageInChatId(payload.chatId);

    if (!currentState || !isConsultationOpened) {
      showNewMessageAlert();

      return;
    }

    if (currentState.loading) {
      return;
    }

    chatApi
      .getChatHistory(payload.chatId, 1, payload.messageId)
      .then((response) => {
        if (response) {
          const updated = state.value;
          const updatedCurrent = state.value[payload.chatId] || {};
          const _messages =
            updatedCurrent.loading === false ? updatedCurrent.messages : [];

          const stateMutationPayload = {
            ...updated,
            [payload.chatId]: {
              ...updatedCurrent,
              messages: _messages.concat(response),
            },
          };

          state.value = stateMutationPayload;

          response.forEach((dtoMessage) => {
            markMessage({ messageId: dtoMessage.messageId, status: 'sent' });
          });

          seedToMessageId.value = {
            ...seedToMessageId.value,
            [payload.seed]: payload.messageId,
          };
        }

        return null;
      })
      .catch(() => {
        alertsService.show(
          'Не удалось загрузить новое сообщение. Попробуйте перезагрузить страницу',
          { type: 'error' }
        );
      });
  };

  const markMessageAsRead = (payload: { messageId: string }) => {
    markMessage({ messageId: payload.messageId, status: 'read' });
  };

  const changeState = (payload: { chatId: ChatId; value: DTOChatState }) => {
    const _state = state.value;
    const currentState = _state[payload.chatId];

    if (!currentState || currentState.loading) {
      return;
    }

    const stateMutationPayload = {
      ..._state,
      [payload.chatId]: {
        ...currentState,
        status: payload.value,
      },
    };

    state.value = stateMutationPayload;
  };

  const updateMessage = (payload: {
    chatId: ChatId;
    message: {
      seed: number;
      text: string;
      attachments: { fileId: number }[];
    };
  }) => {
    return chatApi.sendMessage(Number(payload.chatId), payload.message);
  };

  const safeLoadMore = (payload: { chatId: ChatId }) => {
    const _state = state.value;
    const currentState = _state[payload.chatId];

    if (!currentState || currentState.loading) {
      return;
    }

    const firstMessage = currentState.messages[0];

    if (!firstMessage) {
      return;
    }

    const stateMutationPayload = {
      ..._state,
      [payload.chatId]: {
        ...currentState,
        loadingMore: true,
      },
    };

    state.value = stateMutationPayload;

    chatApi
      .getChatHistory(
        payload.chatId,
        CHAT_MESSAGES_CHUNK,
        firstMessage.messageId
      )
      .then((messages) => {
        if (messages) {
          const maybeUpdated = state.value[payload.chatId];

          if (!maybeUpdated || maybeUpdated.loading) {
            return;
          }

          const filteredMessages = messages.filter(
            (item) => item.messageId !== firstMessage.messageId
          );

          const stateMutationPayload = {
            ...state.value,
            [payload.chatId]: {
              ...maybeUpdated,
              allowMore: messages.length === CHAT_MESSAGES_CHUNK,
              loadingMore: false,
              messages: filteredMessages.concat(maybeUpdated.messages),
            },
          };

          state.value = stateMutationPayload;
        }
      })
      .catch(() => {
        alertsService.show('Не удалось загрузить историю. Попробуйте еще раз', {
          type: 'error',
        });

        const maybeUpdated = state.value[payload.chatId];

        if (!maybeUpdated || maybeUpdated.loading) {
          return;
        }

        const stateMutationPayload = {
          ...state.value,
          [payload.chatId]: {
            ...maybeUpdated,
            loadingMore: false,
          },
        };

        state.value = stateMutationPayload;
      });
  };

  const downloadBlobs = (payload: {
    messageId: string;
    fileData: DTOChatFileData[];
    userId: number | string;
    chatId: string;
  }) => {
    if (payload.fileData.length === 0) {
      return;
    }

    const [dtoFile] = payload.fileData;

    if (messageIdToBlobSrc.value[payload.messageId] !== undefined) {
      return;
    }

    // filter downloading files

    messageIdToBlobSrc.value = {
      ...messageIdToBlobSrc.value,
      [payload.messageId]: '',
    };

    chatApi
      .downloadBlob({
        fileId: dtoFile.fileId,
        chatId: payload.chatId,
        userId: payload.userId,
      })
      .then((response) => {
        if (response) {
          const url = window.URL.createObjectURL(response);

          messageIdToBlobSrc.value = {
            ...messageIdToBlobSrc.value,
            [payload.messageId]: url,
          };
        }
      })
      .catch(() => {
        messageIdToBlobSrc.value = {
          ...messageIdToBlobSrc.value,
          [payload.messageId]: undefined,
        };
      });
  };

  const findFilesAndTryToDownloadThem = (payload: {
    value: DTOChatMessage[];
    chatId: string;
  }) => {
    payload.value.forEach((item) => {
      if (item.type !== DTOChatMessageType.Content) {
        return;
      }

      const data = item.data.filter(
        (el) => 'fileId' in el && !!el.fileId
      ) as DTOChatFileData[];

      const downloadBlobsPayload = {
        messageId: item.messageId,
        fileData: data,
        userId: item.userId,
        chatId: payload.chatId,
      };

      downloadBlobs(downloadBlobsPayload);
    });
  };

  return {
    state,
    lastNewMessageInChatId,
    messageStatusesState,
    seedToMessageId,
    messageIdToBlobSrc,

    loadHistoryAfterNewMessage,
    markMessageAsRead,
    changeState,
    updateMessage,
    safeLoadMore,
    loadChatHistory,
    safeLoadChat,
    findFilesAndTryToDownloadThem,
  };
});
