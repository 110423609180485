import { PatientsResponse } from '@/libs/Patients/http/PatientsResponse.interface';
import { AgeLevel } from '@/libs/User/types/AgeLevel';

import { Feature } from './Feature.enum';
import { FeatureBlockedDescription, FeaturesResponse } from './Feature.types';
import { findFeatureByAgeLevel } from './findFeatureByAgeLevel';

export const getBlockedDescription = (
  features: ReadonlyArray<Feature>,
  state: FeaturesResponse,
  activePatient: PatientsResponse | null
): FeatureBlockedDescription['data'] | null => {
  if (!features || !Array.isArray(features) || features.length === 0) {
    return null;
  }

  const [firstFeature, ...rest] = features;

  const featureForAll = findFeatureByAgeLevel({
    findFeature: firstFeature,
    features: state,
    ageLevel: AgeLevel.any,
  });

  const absolutlyUnavailable = featureForAll && !featureForAll.available;

  if (absolutlyUnavailable) {
    return featureForAll.description?.data ?? [];
  }

  if (!activePatient) {
    return getBlockedDescription(rest, state, activePatient);
  }

  const featureForUser = findFeatureByAgeLevel({
    findFeature: firstFeature,
    features: state,
    ageLevel: activePatient.ageLevel,
  });

  const isAvailable = featureForUser && featureForUser.available;

  if (isAvailable || !featureForUser) {
    return getBlockedDescription(rest, state, activePatient);
  }

  return featureForUser.description?.data ?? [];
};
