import { AgeLevel } from '@/libs/User/types/AgeLevel';

import { Feature } from './Feature.enum';
import { FeaturesResponse } from './Feature.types';

export const findFeatureByAgeLevel = ({
  findFeature,
  features,
  ageLevel,
}: {
  findFeature: Feature;
  features: FeaturesResponse;
  ageLevel: AgeLevel;
}) => {
  if (!features || !Array.isArray(features)) {
    return undefined;
  }

  return features.find(
    (featureItem) =>
      featureItem.name === findFeature && featureItem.ageLevel === ageLevel
  );
};
