import { RouteRecordRaw } from 'vue-router';

export const doctorsRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: '/doctors',
    name: 'DoctorsRoot',
    component: () => import('@/libs/Layouts/SimpleLayout.vue'),
    children: [
      {
        path: 'tmp',
        name: 'MedsiDoctorDetail',
        component: () => import('./views/MedsiDoctorDetail.vue'),
        props: (route) => ({ doctorMisId: Number(route.query.doctorMisId) }),
      },
      {
        path: ':id',
        name: 'DoctorDetail',
        component: () => import('./views/DoctorDetail.vue'),
        props: (route) => ({ id: Number(route.params.id) }),
        meta: {
          tabTitle: 'Профиль врача',
        },
      },
      {
        path: 's/:id',
        name: 'SmartmedDoctor',
        component: () => import('./views/SmartmedDoctor.vue'),
        props: (route) => ({ id: route.params.id }),
        meta: {
          tabTitle: 'Профиль врача',
        },
      },
      {
        path: 's/confirm',
        name: 'SmartmedDoctorAppointmentConfirm',
        component: () => import('./views/Confirm.vue'),
        meta: {
          tabTitle: 'Подтверждение',
        },
      },
      {
        path: '',
        name: 'Doctors',
        component: () => import(`./views/Doctors.vue`),
        meta: {
          tabTitle: 'Врачи',
        },
      },
    ],
  },
];
