import { errorCodes } from '@/libs/Http/errorCodes';

export const mapOfResponseMessages = {
  [errorCodes.UserNotRegistered]: {
    descr: 'Номер не зарегистрирован',
    paramName: 'login',
  },
  [errorCodes.SmsCodeTooMany]: {
    descr: 'Слишком много запросов SMS. Подождите',
    paramName: '',
  },
  [errorCodes.SmsCodeIncorrect]: {
    descr: 'Введите правильный SMS-код',
    paramName: 'code',
  },
  [errorCodes.AuthError]: {
    descr: 'Неверный пароль',
    paramName: 'password',
  },
  [errorCodes.FormatError]: {
    descr: '',
    paramName: '',
  },
  [errorCodes.UserCreatedEarlier]: {
    descr: 'Номер уже зарегистрирован',
    paramName: 'phone',
  },
};
