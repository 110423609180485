import { AgeLevel } from '@/libs/User/types/AgeLevel';
import { UserInfoType } from '@/libs/User/types/Info/UserInfo.type';

import { Feature } from './Feature.enum';
import { FeaturesResponse } from './Feature.types';
import { findFeatureByAgeLevel } from './findFeatureByAgeLevel';

export const isFeatureAvailable = (
  features: ReadonlyArray<Feature>,
  state: FeaturesResponse,
  activePatient: UserInfoType | null
): boolean => {
  if (!features || !Array.isArray(features) || features.length === 0) {
    return false;
  }

  const allFeature = findFeatureByAgeLevel({
    findFeature: Feature.All,
    features: state,
    ageLevel: AgeLevel.any,
  });

  if (allFeature && !allFeature.available) {
    return false;
  }

  const [featureToCheck, ...rest] = features;

  const featureForAll = findFeatureByAgeLevel({
    findFeature: featureToCheck,
    features: state,
    ageLevel: AgeLevel.any,
  });

  const absolutlyUnavailable = featureForAll && !featureForAll.available;

  if (absolutlyUnavailable) {
    return false;
  }

  if (!activePatient) {
    return isFeatureAvailable(rest, state, activePatient);
  }

  const featureForUser = findFeatureByAgeLevel({
    findFeature: featureToCheck,
    features: state,
    ageLevel: activePatient.ageLevel,
  });

  const isAvailable = featureForUser && featureForUser.available;

  return !!isAvailable;
};
