import { watch } from 'vue';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import { storeToRefs } from 'pinia';

import { useLoyalityStore } from '@/libs/Loyality';

const waitUntilUserAndLoyalityResolved = (
  shouldEnabled: boolean
): Promise<boolean> => {
  const store = useLoyalityStore();
  const { enabledActivePatient } = storeToRefs(store);

  return new Promise((resolve) => {
    const isAvailable = enabledActivePatient.value;

    if (isAvailable === null) {
      const unwatch = watch(enabledActivePatient, (value) => {
        if (typeof value === 'boolean') {
          unwatch();
          resolve(value === shouldEnabled);
        }
      });
    } else {
      resolve(isAvailable === shouldEnabled);
    }
  });
};

export async function loyalityHistoryGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const isAvailable = await waitUntilUserAndLoyalityResolved(true);

  if (isAvailable) {
    next();
  } else {
    next({ name: 'LoyalityActivation' });
  }
}

export async function loyalitySmsConfirmGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const isAvailable = await waitUntilUserAndLoyalityResolved(true);

  const hasRequiredParams =
    !!to.query.userId && !!to.query.bonuses && !!to.query.phone;

  if (isAvailable && hasRequiredParams) {
    next();
  } else {
    next(from.path === '/' ? { name: 'LoyalityHistory' } : from);
  }
}
