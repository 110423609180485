import { computed, ref } from 'vue';

import { defineStore } from 'pinia';

import { getFirstChar } from '@/shared/Utility/String/getFirstChar';

import { useResetOnLogout } from '../Authorization/use/useResetOnLogout';
import { UserInfoType } from './types/Info/UserInfo.type';

export const useUserInfoStore = defineStore('useUserInfoStore', () => {
  const info = ref<UserInfoType | null>(null);

  const reset = () => {
    info.value = null;
  };

  useResetOnLogout(reset);

  const firstChar = computed(() => {
    const value = info.value;
    const name = value?.firstName || '';

    return getFirstChar(name);
  });

  const set = (value: UserInfoType | null) => {
    info.value = value;
  };

  return {
    info,

    firstChar,

    set,
  };
});
