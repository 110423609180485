import { CHAR_NO_BREAK_SPACE } from '@smartmed/utility/consts';

type Geolocation = { latitude: number; longitude: number };

export enum PermissionState {
  GRANTED = 'granted',
  DENIED = 'denied',
  UNKNOWN = 'prompt',
}

const EQUATORIAL_RADIUS_IN_METERS = 6378245;

export class UserLocationService {
  private currentLocation: Geolocation | null = null;
  private state = PermissionState.UNKNOWN;
  private onChangeCallbacks: ReadonlyArray<(value: PermissionState) => void> =
    [];

  constructor() {
    if (!navigator.permissions) {
      return;
    }

    navigator.permissions.query({ name: 'geolocation' }).then((status) => {
      this.state = status.state as PermissionState;

      this.onStateChanged();
    });
  }

  static deg2rad(deg: number): number {
    return (deg * Math.PI) / 180;
  }

  static formatDistance(distance: number): string {
    if (distance < 1000) {
      return `${distance}${CHAR_NO_BREAK_SPACE}м`;
    }

    return `${(distance / 1000).toFixed(1)}${CHAR_NO_BREAK_SPACE}км`;
  }

  getState() {
    return this.state;
  }

  requestLocation() {
    this.setCurrentLocation();
  }

  getLocation() {
    return this.currentLocation;
  }

  calculateDistance(latitude: number, longitude: number): number | null {
    if (!this.currentLocation) {
      return null;
    }

    const toLatitude = UserLocationService.deg2rad(latitude);
    const toLongitude = UserLocationService.deg2rad(longitude);

    const currentLatitude = UserLocationService.deg2rad(
      this.currentLocation.latitude
    );
    const currentLongitude = UserLocationService.deg2rad(
      this.currentLocation.longitude
    );

    const to = { latitude: toLatitude, longitude: toLongitude };
    const from = { latitude: currentLatitude, longitude: currentLongitude };

    const distance = this.getDistanceBetweenTwoPoints(to, from);

    return Math.round(distance);
  }

  registerOnChangeState(fn: (value: PermissionState) => void) {
    this.onChangeCallbacks = [...this.onChangeCallbacks, fn];
  }

  unregisterOnChangeState(fn: (value: PermissionState) => void) {
    this.onChangeCallbacks = this.onChangeCallbacks.filter(
      (callback) => callback !== fn
    );
  }

  decline() {
    this.state = PermissionState.DENIED;
    this.handleStateChangeHooks(this.state);
  }

  private handleStateChangeHooks(state: PermissionState) {
    this.onChangeCallbacks.forEach((fn) => fn(state));
  }

  private getDistanceBetweenTwoPoints(
    to: Geolocation,
    from: Geolocation
  ): number {
    const cosComponent =
      Math.cos(to.latitude) *
      Math.cos(from.latitude) *
      Math.cos(to.longitude - from.longitude);
    const sinComponent = Math.sin(to.latitude) * Math.sin(from.latitude);

    const distance =
      EQUATORIAL_RADIUS_IN_METERS * Math.acos(cosComponent + sinComponent);

    return distance;
  }

  private onStateChanged() {
    this.handleStateChangeHooks(this.state);

    if (this.state === PermissionState.GRANTED) {
      this.setCurrentLocation();
    }
  }

  private setCurrentLocation() {
    if (!navigator.geolocation) {
      return;
    }

    if (this.state === PermissionState.DENIED) {
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        this.currentLocation = {
          latitude,
          longitude,
        };

        this.state = PermissionState.GRANTED;
        this.handleStateChangeHooks(this.state);
      },
      () => {
        this.state = PermissionState.DENIED;
        this.handleStateChangeHooks(this.state);
      },
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 10000 }
    );
  }
}

export const userLocationService = new UserLocationService();
