import {
  NavigationGuard,
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';

type Guard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => boolean;

export function guardsBuilder(
  guards: ReadonlyArray<Guard>,
  pathToBack = '/'
): NavigationGuard {
  return (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const isValid = guards.every((guard) => guard.call(null, to, from));

    if (isValid) {
      next();
    } else {
      const routeBack = from.name
        ? { name: from.name, query: from.query }
        : { path: pathToBack };

      next(routeBack);
    }
  };
}
