import { InjectionKey, Plugin, Ref, ref, watch } from 'vue';

type Theme = 'light' | 'dark';

export const THEME_TOKEN = Symbol() as InjectionKey<Ref<Theme>>;

export const ThemePlugin: Plugin = {
  install(app) {
    const browserTheme =
      window &&
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;
    const ls = localStorage.getItem('__smed-theme');
    const valid =
      ls === 'light' || ls === 'dark' ? ls : browserTheme ? 'dark' : 'light';

    const theme = ref<Theme>(valid);

    const setAttribute = (value: Theme) => {
      document.documentElement.setAttribute('data-theme', value);

      localStorage.setItem('__smed-theme', value);
    };

    app.provide(THEME_TOKEN, theme);

    watch(theme, setAttribute, { immediate: true });
  },
};
