import { ref } from 'vue';

import { createCookieTokenStorage } from '@smartmed/http/useRequest/storage/cookie';
import { defineStore } from 'pinia';

import { SignInStatus } from '@/libs/Authorization/types/SignInStatus.enum';
import { httpClient } from '@/libs/Http';
import { Response } from '@/libs/Http/Response';

import {
  DTORefreshTokenRequest,
  DTORefreshTokenResponse,
} from '../types/dto/DTORefreshToken';
import {
  DTOSignInRequest,
  DTOSignInResponse,
  DTOSignInSmsConfirmResponse,
} from '../types/dto/DTOSignIn';
import {
  DTOSignUpRequest,
  DTOSignUpResponse,
  DTOSignUpSmsConfirmRequest,
  DTOSignUpSmsConfirmResponse,
} from '../types/dto/DTOSignUp';
import { getUserRegistrationStatus } from './authApiHelpers';
import { _internalReset } from './useResetOnLogout';

const COOKIE_PATH = GLOBAL_CONFIG.BASE_HREF || '/';

type SaveTokens = Pick<
  Response<{
    token?: string | null;
    refreshToken?: string | null;
  }>,
  'payload'
> | null;

export const accessTokenStorage = createCookieTokenStorage('_smed-at', {
  path: COOKIE_PATH,
});

export const useAuthorization = defineStore('useAuthorization', () => {
  const refreshTokenStorage = createCookieTokenStorage('_smed-rt', {
    path: COOKIE_PATH,
  });

  const authTokenRef = ref(accessTokenStorage.get() || null);

  const checkPhone = (phone: string) => {
    return httpClient
      .get<null>('/users/check/v3', {
        params: { phone },
      })
      .then(getUserRegistrationStatus)
      .catch(() => SignInStatus.Created);
  };

  function signin(
    body: Omit<DTOSignInRequest, 'smsCode'>,
    captchaToken: string
  ): Promise<Response<DTOSignInSmsConfirmResponse>>;
  function signin(
    body: DTOSignInRequest,
    captchaToken: string
  ): Promise<Response<DTOSignInResponse>>;

  function signin(body: unknown, captchaToken: string): unknown {
    return httpClient
      .post<DTOSignInSmsConfirmResponse | DTOSignInResponse, unknown>(
        '/users/logon/v5',
        body,
        {
          headers: {
            'captcha-token': captchaToken,
          },
        }
      )
      .then((response) => new Response({ ...response, isMapMessages: true }))
      .then((response) => {
        saveTokensIfPossible(response);

        return response;
      });
  }

  function signup(
    body: DTOSignUpSmsConfirmRequest,
    captchaToken: string
  ): Promise<Response<DTOSignUpSmsConfirmResponse>>;
  function signup(
    body: DTOSignUpRequest,
    captchaToken: string
  ): Promise<Response<DTOSignUpResponse>>;

  function signup(body: unknown, captchaToken: string): unknown {
    return httpClient
      .post<DTOSignUpResponse | DTOSignUpSmsConfirmResponse, unknown>(
        '/users/register/v4',
        body,
        {
          headers: {
            'captcha-token': captchaToken,
          },
        }
      )
      .then((response) => new Response({ ...response, isMapMessages: true }))
      .then((response) => {
        saveTokensIfPossible(response);

        return response;
      });
  }

  const logout = () => {
    return httpClient.post('/users/logoff/v4').then((response) => {
      return deleteTokens().then(() => response);
    });
  };

  const refreshToken = () => {
    const body = { refreshToken: refreshTokenStorage.get() || '' };

    return httpClient
      .post<DTORefreshTokenResponse, DTORefreshTokenRequest>(
        '/users/logonbytoken/v2',
        body
      )
      .then((response) => new Response(response))
      .then((response) => {
        saveTokensIfPossible(response);

        return response;
      });
  };

  const saveTokensIfPossible = (response: SaveTokens) => {
    const data = (response && response.payload.data) || null;

    if (!data) {
      return;
    }

    data.refreshToken && refreshTokenStorage.set(data.refreshToken);

    if (data.token) {
      accessTokenStorage.set(data.token);
      authTokenRef.value = data.token;
    }
  };

  const deleteTokens = () => {
    accessTokenStorage.delete();
    refreshTokenStorage.delete();

    authTokenRef.value = null;
    _internalReset.value = Date.now();

    return Promise.resolve(null);
  };

  const setExternalToken = (token: string | null) => {
    saveTokensIfPossible({
      payload: { data: { token }, messages: { common: [], byFields: {} } },
    });
  };

  const getToken = () => accessTokenStorage.get();

  const isAuth = () => !!getToken();

  const loginViaQueryToken = (qtoken: string) => {
    return httpClient
      .post<
        {
          token: 'string';
          refreshToken: 'string';
        },
        { accessToken: string }
      >('/users/logonbymobiletoken', {
        accessToken: qtoken,
      })
      .then((response) => new Response(response))
      .then((r) => {
        saveTokensIfPossible(r);

        return (
          !!r.payload &&
          !!r.payload.data &&
          !!r.payload.data.refreshToken &&
          !!r.payload.data.token
        );
      });
  };

  return {
    checkPhone,
    signin,
    signup,
    logout,
    refreshToken,
    getToken,
    setExternalToken,
    isAuth,
    deleteTokens,
    authTokenRef,
    loginViaQueryToken,
  };
});
