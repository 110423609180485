import { firstLetterToUpperCase } from '@/shared/Utility/String/firstLetterToUpperCase';

type CamelCase<S extends string> =
  S extends `${infer P1}_${infer P2}${infer P3}`
    ? `${P1}${Uppercase<P2>}${CamelCase<P3>}`
    : S;

export type Camelize<T> = {
  [K in keyof T as CamelCase<string & K>]: T[K] extends Array<infer U>
    ? Array<CamelizeObject<U>>
    : T[K] extends ReadonlyArray<infer U>
    ? ReadonlyArray<CamelizeObject<U>>
    : CamelizeObject<T[K]>;
};

type CamelizeObject<T> = T extends {}
  ? Camelize<T>
  : T extends {} | null
  ? Camelize<T>
  : T;

function isPrimitive(
  data: any
): data is string | boolean | number | null | undefined {
  return (
    typeof data === 'string' ||
    typeof data === 'boolean' ||
    typeof data === 'number' ||
    data === undefined ||
    data === null
  );
}

function capitalize(text: string): string {
  return firstLetterToUpperCase(text.toLowerCase());
}

function toCamelCase<T extends string = string>(str: T): CamelCase<T> {
  if (!str.includes('_')) {
    return str as CamelCase<T>;
  }

  return str
    .split(/_/g)
    .map((m, index) => (index === 0 ? m.toLowerCase() : capitalize(m)))
    .join('') as CamelCase<T>;
}

function deep<T>(data: T): any {
  if (isPrimitive(data)) {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map(deep);
  }

  if (data !== null && typeof data === 'object') {
    const keys = Object.keys(data) as any as ReadonlyArray<
      Extract<keyof T, string>
    >;

    return keys.reduce((result, key) => {
      result[toCamelCase(key)] = deep(data[key]);

      return result;
    }, {} as Record<CamelCase<string>, any>);
  }

  return data;
}

export function pythonDtoMapper<T>(
  data: T
): T extends string ? T : Camelize<T> {
  return isPrimitive(data) ? data : deep(data);
}
