import { FeatureToggleRepository } from './FeatureToggle.repository';

export class FeatureToggleService {
  constructor(private readonly repository: FeatureToggleRepository) {}

  load() {
    return this.repository
      .getFeatures()
      .then(({ success, payload }) =>
        success ? payload.data : Promise.reject([])
      );
  }
}

export const featureToggleService = new FeatureToggleService(
  new FeatureToggleRepository()
);
