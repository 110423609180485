import { UpdateInfoRequest } from '../../types/Info/UpdateInfoRequest.type';
import { UserPassportRequest } from '../../types/Info/UserPassportRequest.type';
import { UserInfoRepository } from './UserInfo.repository';

export class UserInfoService {
  constructor(private readonly userInfoRepository: UserInfoRepository) {}

  get() {
    return this.userInfoRepository.get().then(({ success, payload }) => {
      if (success && !payload.data) {
        return {
          success: false,
          payload: {
            data: null,
            messages: {
              common: [
                'Произошла ошибка при загрузке информации пользователя. Мы уже работаем над исправлением',
              ],
            },
          },
        };
      }

      const errors =
        (!success &&
          payload.messages.common.filter(
            (m) => m !== 'Invalid session token'
          )) ||
        [];

      return {
        success,
        payload: {
          ...payload,
          messages: {
            common: errors,
          },
        },
      };
    });
  }

  getInfoForEdit(userId: number) {
    return this.userInfoRepository
      .getInfoForEdit(userId)
      .then((response) =>
        response.success
          ? response.payload
          : Promise.reject(response.payload.messages)
      );
  }

  updateInfo(request: UpdateInfoRequest) {
    return this.userInfoRepository
      .updateInfo(request)
      .then((response) =>
        response.success
          ? response.payload
          : Promise.reject(response.payload.messages)
      );
  }

  getUserInfo() {
    return this.userInfoRepository
      .getUserInfo()
      .then((response) =>
        response.success
          ? response.payload.data
          : Promise.reject(response.payload.messages)
      );
  }

  getPassport() {
    return this.userInfoRepository
      .getPassport()
      .then((response) =>
        response.success
          ? response.payload.data
          : Promise.reject(response.payload.messages)
      );
  }

  updatePassport(request: UserPassportRequest) {
    return this.userInfoRepository
      .updatePassport(request)
      .then((response) =>
        response.success
          ? response.payload.data
          : Promise.reject(response.payload.messages)
      );
  }
}

export const userInfoService = new UserInfoService(new UserInfoRepository());
