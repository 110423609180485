import axios, { AxiosInstance, AxiosRequestConfig, Method } from 'axios';

import { Connection } from './Connection.interface';
import { createAbortableAxiosRequest } from './createAbortableAxiosRequest';
import { CustomAxiosRequestConfig } from './CustomAxiosRequestConfig.interface';
import { ResolvedResponse } from './ResolvedResponse.type';

export class Axios implements Connection {
  private readonly instance: AxiosInstance;

  constructor(config: AxiosRequestConfig) {
    this.instance = axios.create(config);
  }

  getInstance(): AxiosInstance {
    return this.instance;
  }

  async request<
    TResponse,
    TData,
    TConfig extends CustomAxiosRequestConfig = CustomAxiosRequestConfig
  >(
    url: string,
    method: Method,
    data?: TData,
    config?: TConfig
  ): Promise<ResolvedResponse<TResponse, TConfig>> {
    return createAbortableAxiosRequest<ResolvedResponse<TResponse, TConfig>>(
      this.instance.request.bind(this.instance)
    )({
      ...(config || {}),
      url,
      method,
      data,
    });
  }
}
