import { useRouter } from 'vue-router';

import { provideHttpInstance } from '@patient/endpoints/useHttp';
import { createUseRequest } from '@smartmed/http/useRequest';
import {
  createAccessTokenInterceptor,
  createInterceptor,
} from '@smartmed/http/useRequest/interceptors';
import { TokenStorage } from '@smartmed/http/useRequest/types';
import { noop } from '@smartmed/utility/functions';
import { AxiosRequestConfig } from 'axios';
import { storeToRefs } from 'pinia';

import { useAuthorization } from '@/libs/Authorization/use/useAuthorization';
import { usePatientsStore } from '@/libs/Patients/usePatientsStore';
import { getLocalDateTimeWithOffset } from '@/shared/Utility/Date/getLocalDateTime';

import { errorCodes } from '../Http/errorCodes';
import { ServerPayload } from '../Http/Response';

const baseHeaders = {
  'Application-Version': GLOBAL_CONFIG.APPLICATION_VERSION,
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Pragma: 'no-cache',
};

export const getApplicationHeaders = () => {
  return {
    ...baseHeaders,
    CurrentDate: getLocalDateTimeWithOffset(),
    'Timezone-Offset': Math.abs(new Date().getTimezoneOffset()),
  };
};

const isProbablyAxiosResponse = (response: any) => {
  return 'request' in response && response.request instanceof XMLHttpRequest;
};

const forceInferDataInterceptor = createInterceptor((interceptor) => {
  interceptor.response.use(
    (response) =>
      isProbablyAxiosResponse(response) ? response.data : response,
    (error) => Promise.reject(error)
  );
});

const baseHeaderInterceptor = createInterceptor<AxiosRequestConfig>(
  (interceptor) => {
    interceptor.request.use((config) => {
      config.headers = {
        ...getApplicationHeaders(),
        ...config.headers,
      };

      return config;
    });
  }
);

const fileServiceRejectPromiseOnError = createInterceptor<AxiosRequestConfig>(
  (interceptor) => {
    interceptor.response.use(
      (response) => {
        if (response instanceof Blob && response.type === 'application/json') {
          return response.text
            ? response.text().then((r) => Promise.reject(r))
            : Promise.reject(response);
        }

        return response;
      },
      (error) => Promise.reject(error)
    );
  }
);

export function setupCoreModule() {
  const patientsStore = usePatientsStore();
  const { activePatient } = storeToRefs(patientsStore);

  const auth = useAuthorization();
  const router = useRouter();

  const storage: TokenStorage = {
    get: auth.getToken,
    set: noop,
    delete: auth.deleteTokens,
  };

  const refresh = () => {
    return auth.refreshToken().then((r) => {
      return r.payload.data?.token;
    });
  };

  const afterRefreshFailure = () => {
    return router.push({
      name: 'SignIn',
      query: {
        from: encodeURIComponent(router.currentRoute.value.fullPath),
      },
    });
  };

  const covertTokenExpiredTo401 = createInterceptor((interceptor) => {
    interceptor.response.use((resp) => {
      const data = resp.data as ServerPayload<unknown>;

      if (data && data.statusInfo && data.statusInfo.infoMessages) {
        const expired = data.statusInfo.infoMessages.find(
          (item) => item.code === errorCodes.SessionTokenError
        );

        if (expired) {
          resp.status = 401;

          return Promise.reject({
            status: 401,
            response: resp,
            config: resp.config,
          });
        }
      }

      return resp;
    });
  });

  const authInterceptor = createAccessTokenInterceptor(
    storage,
    refresh,
    afterRefreshFailure
  );

  const patientIdInterceptor = createInterceptor<AxiosRequestConfig>(
    (interceptor) => {
      interceptor.request.use((config) => {
        const patient = activePatient.value;
        const headers = config.headers || {};

        if (patient && !headers.PatientId) {
          config.headers = {
            ...headers,
            PatientId: patient.userId,
          };
        }

        return config;
      });
    }
  );

  const useKongRequest = createUseRequest<{}, { inferData: true }>(
    GLOBAL_CONFIG.BASE_URL_SMARTMED,
    [
      baseHeaderInterceptor,
      covertTokenExpiredTo401,
      authInterceptor,
      patientIdInterceptor,
      forceInferDataInterceptor,
    ]
  );

  const useGatewayRequest = createUseRequest<{}, { inferData: true }>(
    GLOBAL_CONFIG.BASE_URL_PROXY,
    [
      baseHeaderInterceptor,
      covertTokenExpiredTo401,
      authInterceptor,
      patientIdInterceptor,
      forceInferDataInterceptor,
      fileServiceRejectPromiseOnError,
    ]
  );

  provideHttpInstance('kong', useKongRequest);
  provideHttpInstance('gateway', useGatewayRequest);
}
