import { ref } from 'vue';

import { httpClient } from '@/libs/Http';

const hasChronicsAccess = ref<null | boolean>(null);

export function useChronics() {
  const getCachedHasAccess = (force = false) => {
    const value = hasChronicsAccess.value;

    if (!force && value !== null) {
      return Promise.resolve(value);
    }

    const request = httpClient.get<boolean>(`/chronics/patient/check`, {
      isSmartmed: true,
    });

    return request
      .then(({ success, payload }) => {
        return success ? payload.data : false;
      })
      .catch(() => false)
      .then((resp) => {
        hasChronicsAccess.value = resp;

        return resp;
      });
  };

  const clearHasChronicsAccess = () => {
    hasChronicsAccess.value = null;
  };

  return {
    getCachedHasAccess,
    hasChronicsAccess,
    clearHasChronicsAccess,
  };
}
