import { RouteRecordRaw } from 'vue-router';

import { unauthzoneGuard } from '@/libs/Authorization/guards/Unauthzone/UnauthzoneGuard';
import { guardsBuilder } from '@/libs/Http/guardsBuilder';

export const signUpRoute: ReadonlyArray<RouteRecordRaw> = [
  {
    path: '/sign-up',
    component: () => import('../Authorization.vue'),
    beforeEnter: guardsBuilder([unauthzoneGuard], '/sign-in'),
    meta: {
      savedPosition: true,
      allowUnauth: true,
      tabTitle: 'Новый личный кабинет Медси. Регистрация',
    },
    children: [
      {
        path: '',
        name: 'SignUp',
        component: () => import('./views/SignUp.vue'),
      },
      {
        path: 'fill',
        name: 'SignUpFill',
        component: () => import('./views/SignUpFill.vue'),
      },
    ],
  },
];
