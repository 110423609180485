import { AxiosResponse } from 'axios';

import { InterceptorFn } from '@/libs/Http/setupInterceptors';

export function blobTypeInterceptor(): InterceptorFn {
  return (interceptors) => {
    interceptors.response.use((response: AxiosResponse<any>) => {
      const config = response.config || {};
      const isBlob = config.responseType === 'blob';

      if (!isBlob) {
        return response;
      }

      const data = response.data as Blob;
      const isJsonBlob = data.type === 'application/json';

      if (!isJsonBlob) {
        return response;
      }

      return data
        .text()
        .then((value) => {
          response.data = JSON.parse(value);

          return response;
        })
        .catch(() => response);
    });
  };
}
