import { defineAsyncComponent } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { cardsRoutes } from './views/Cards/route';
import { depositsRoutes } from './views/Deposits/route';
import { invoicesRoutes } from './views/Invoices/route';
import { paymentHistoryRoutes } from './views/PaymentHistory/route';

const wallet = {
  path: '',
  name: 'Wallet',
  component: () => import('./views/Wallet.vue'),
};

const children = ([] as ReadonlyArray<RouteRecordRaw>)
  .concat(cardsRoutes)
  .concat(depositsRoutes)
  .concat(invoicesRoutes)
  .concat(paymentHistoryRoutes)
  .concat(wallet);

export const walletRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: 'wallet',
    name: 'WalletRoot',
    component: () => import('@/libs/Layouts/SimpleLayout.vue'),
    meta: {
      layout: defineAsyncComponent(
        () => import('@/libs/Layouts/LayoutDefault.vue')
      ),
      tabTitle: 'Мой кошелёк',
    },
    children,
  },
];
