import { RouteRecordRaw } from 'vue-router';

export const onlineConsultationRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: '/online-consultation',
    meta: {
      tabTitle: 'Запись на онлайн-консультацию',
    },
    component: () => import('./views/OnlineConsultationRoot.vue'),
    children: [
      {
        path: '',
        name: 'OnlineConsultation',
        component: () => import('./views/NewOnline.vue'),
        meta: {
          title: 'Запись на онлайн-консультацию',
        },
      },
      {
        path: 'doctors',
        name: 'OnlineConsultationDoctors',
        component: () =>
          import('./views/doctors/OnlineConsultationDoctors.vue'),
        meta: {
          title: 'Запись на онлайн-консультацию',
        },
      },
      {
        path: 'submit',
        name: 'OnlineConsultationSubmit',
        component: () =>
          import('./views/submit/views/OnlineConsultationSubmit.vue'),
        meta: {
          title: 'Запись на онлайн-консультацию',
        },
      },
      {
        path: 'n/submit',
        name: 'NewOnlineConsultationSubmit',
        component: () => import('./views/submit/views/NewOnlineSubmit.vue'),
        meta: {
          title: 'Запись на онлайн-консультацию',
        },
      },
    ],
  },
];
