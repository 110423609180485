import { Paging } from '@/shared/Types/Smartmed/Paging.type';

import { CityRepository } from './City.repository';

export class CityService {
  constructor(private readonly repository: CityRepository) {}

  getUserCity(latitude?: number, longitude?: number) {
    return this.repository
      .getUserCity(latitude, longitude)
      .then((response) =>
        response.success
          ? response.payload.data
          : Promise.reject(response.payload)
      );
  }

  getCities(pagination: Paging, name?: string) {
    return this.repository
      .getCities(pagination, name)
      .then((response) =>
        response.success
          ? response.payload.data
          : Promise.reject(response.payload)
      );
  }
}

export const cityService = new CityService(new CityRepository());
