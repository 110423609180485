import { computed, ref, watch } from 'vue';

import { defineStore } from 'pinia';

import { useResetOnLogout } from '../Authorization/use/useResetOnLogout';
import { patientsService } from './http/Patients.service';
import { PatientsResponse } from './http/PatientsResponse.interface';
import { AddPatientRequest } from './types/AddPatientRequest.type';

export const usePatientsStore = defineStore('usePatientsStore', () => {
  const patients = ref<PatientsResponse[] | null>(null);
  const activePatient = ref<PatientsResponse | null>(null);

  const reset = () => {
    patients.value = null;
    activePatient.value = null;
  };

  useResetOnLogout(reset);

  const hasPatients = computed(() => {
    const _patients = patients.value;

    return !!_patients && _patients.length > 0;
  });

  const isFewPatients = computed(() => {
    const _patients = patients.value;

    return !!_patients && _patients.length > 1;
  });

  const defaultPatient = computed(() => {
    const _patients = patients.value || [];

    return _patients.find(({ isDefault }) => isDefault) || null;
  });

  const setActivePatient = (value: PatientsResponse | null) => {
    activePatient.value = value;
  };

  const setPatients = (value: PatientsResponse[] | null) => {
    patients.value = value;
  };

  const loadAndSetActive = (value: number | undefined) => {
    return patientsService.get().then((response) => {
      setPatients(response);

      const newActivePatient = response.find(({ userId }) => userId === value);

      if (newActivePatient) {
        setActivePatient(newActivePatient);
      } else {
        const oldActivePatient = activePatient.value;
        const patient = oldActivePatient
          ? response.find(({ userId }) => userId === oldActivePatient.userId) ||
            null
          : response[0] || null;

        setActivePatient(patient);
      }
    });
  };

  const addPatient = (value: AddPatientRequest) => {
    return patientsService.add(value).then((response) => {
      if (response) {
        loadAndSetActive(undefined);
      }
    });
  };

  const deletePatient = (value: number) => {
    return patientsService.delete(value).then(() => {
      patients.value = (patients.value || []).filter(
        ({ userId }) => userId !== value
      );
    });
  };

  watch(
    activePatient,
    (value) => {
      if (value) {
        localStorage.setItem('__smed-patient-id', `${value.userId}`);
      } else {
        localStorage.removeItem('__smed-patient-id');
      }
    },
    { immediate: true }
  );

  return {
    patients,
    activePatient,

    hasPatients,
    isFewPatients,
    defaultPatient,

    addPatient,
    setActivePatient,
    setPatients,
    deletePatient,
    loadAndSetActive,
  };
});
