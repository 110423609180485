export class AbortRequestService {
  private keys: symbol[] = [];
  private aborts = new Map<symbol, () => void>();

  registerKey(key: symbol) {
    this.keys = [...this.keys, key];
  }

  deleteKey(key: symbol) {
    this.keys = this.keys.filter((k) => k !== key);
  }

  registerAbortFn(key: symbol, abortCallback: () => void) {
    this.aborts.set(key, abortCallback);
  }

  popKey(): symbol | null {
    return this.keys.pop() || null;
  }

  goAbort(key: symbol) {
    const abortCallback = this.aborts.get(key);

    if (abortCallback) {
      abortCallback();

      this.aborts.delete(key);
    }
  }
}

export const abortRequestService = new AbortRequestService();
