import { httpClient } from '@/libs/Http';
import { Response } from '@/libs/Http/Response';
import { PatientsResponse } from '@/libs/Patients/http/PatientsResponse.interface';

import { LoyalityConfirmRequest } from '../type/LoyalityConfirmRequest.type';
import { LoyalityConfirmResponse } from '../type/LoyalityConfirmResponse.type';
import { LoyalityCouponToken } from '../type/LoyalityCouponToken.type';
import { LoyalityHistory } from '../type/LoyalityHistory';
import { LoyalityHistoryRequest } from '../type/LoyalityHistoryRequest';
import { LoyalityInfoResponse } from '../type/LoyalityInfoResponse';
import { LoyalitySharingBonusInfo } from '../type/LoyalitySharingBonusInfo.type';

const patientIdHeaders = (userId: number) => ({
  headers: { PatientId: userId },
});

let cachedLoyalityLink: string | null = null;

export function useLoyalityApi() {
  const getInfoFor = (patientId: number) => {
    return httpClient
      .post<LoyalityInfoResponse, null>(
        '/loyality/getuserloyalityinfo',
        null,
        patientIdHeaders(patientId)
      )
      .then((response) => new Response(response))
      .then((response) =>
        response.success
          ? response.payload.data
          : Promise.reject(response.payload.messages)
      );
  };

  const getHistory = (
    userId: PatientsResponse['userId'],
    request: LoyalityHistoryRequest
  ) => {
    return httpClient
      .post<ReadonlyArray<LoyalityHistory>, LoyalityHistoryRequest>(
        '/loyality/getbonushistory',
        request,
        patientIdHeaders(userId)
      )
      .then((response) => new Response(response))
      .then((response) =>
        response.success
          ? response.payload.data
          : Promise.reject(response.payload.messages)
      );
  };

  const getSharingBonusInfo = (userId: number) => {
    return httpClient
      .get<ReadonlyArray<LoyalitySharingBonusInfo>>(
        '/loyality/getsharingbonusinfo',
        patientIdHeaders(userId)
      )
      .then((response) => new Response(response))
      .then((response) =>
        response.success
          ? response.payload.data
          : Promise.reject(response.payload.messages)
      );
  };

  const getSharingToken = (bonus: number, userId: number) => {
    return httpClient
      .post<LoyalityCouponToken, { bonusAmount: number }>(
        '/loyality/postcouponcreate',
        { bonusAmount: bonus },
        patientIdHeaders(userId)
      )
      .then((response) => new Response(response))
      .then((response) =>
        response.success
          ? response.payload.data
          : Promise.reject(response.payload.messages)
      );
  };

  const confirmSharing = (request: LoyalityConfirmRequest, userId: number) => {
    return httpClient
      .post<LoyalityConfirmResponse, LoyalityConfirmRequest>(
        '/loyality/postcouponconfirmcode',
        request,
        patientIdHeaders(userId)
      )
      .then((response) => new Response(response))
      .then((response) =>
        response.success
          ? response.payload.data
          : Promise.reject(response.payload.messages)
      );
  };

  const getLoyalityLink = () => {
    if (cachedLoyalityLink) {
      return Promise.resolve(cachedLoyalityLink);
    }

    return httpClient
      .post<{ programRulesUrl: string }, void>(
        '/loyality/getloyalitypartnerinfo'
      )
      .then((response) => new Response(response))
      .then((response) =>
        response.success
          ? response.payload.data
          : Promise.reject(response.payload.messages)
      )
      .then((response) => response?.programRulesUrl || '')
      .then((response) => {
        cachedLoyalityLink = response || null;

        return response;
      })
      .catch(() => '');
  };

  const join = (userId: number) => {
    return httpClient
      .post<void, null>(
        '/loyality/joinloyalityprogram',
        null,
        patientIdHeaders(userId)
      )
      .then((response) => new Response(response))
      .then((response) =>
        response.success
          ? response.payload.data
          : Promise.reject(response.payload.messages)
      );
  };

  return {
    getInfoFor,
    join,
    getLoyalityLink,
    confirmSharing,
    getSharingToken,
    getSharingBonusInfo,
    getHistory,
  };
}
