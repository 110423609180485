import { computed } from 'vue';

import {
  SignalRListener,
  useNotificationHub,
} from '@smartmed/notification-hub';
import { storeToRefs } from 'pinia';

import { useAuthorization } from '@/libs/Authorization/use/useAuthorization';
import { useChronicsChatStore } from '@/libs/ChatChronics/useChronicsChatStore';

import {
  HubChatStateChanged,
  HubMessageNotification,
  HubMessageRead,
} from './hubEvents';

export function setupGlobalChatHub() {
  const authService = useAuthorization();
  const chronicsChatStore = useChronicsChatStore();
  const { authTokenRef } = storeToRefs(authService);

  const authorizationToken = computed(() => {
    const token = authTokenRef.value;

    return token?.replace('Bearer ', '');
  });

  const messagesListeners: SignalRListener[] = [
    {
      eventName: 'receivemessagenotification',
      resolver: (value: HubMessageNotification) => {
        chronicsChatStore.loadHistoryAfterNewMessage({
          chatId: `${value.chatId}`,
          messageId: value.msgId,
          seed: value.seed,
        });
      },
    },
    {
      eventName: 'receivemessagereadstatus',
      resolver: (value: HubMessageRead) => {
        chronicsChatStore.markMessageAsRead({
          messageId: value.msgId,
        });
      },
    },
    {
      eventName: 'receivechatstatechanged',
      resolver: (value: HubChatStateChanged) => {
        chronicsChatStore.changeState({
          chatId: `${value.chatId}`,
          value: value.chatStateType,
        });
      },
    },
  ];

  const { state } = useNotificationHub(
    { endpoint: GLOBAL_CONFIG.NOTIFICATION_HUB_CHAT, transport: 1 },
    authorizationToken,
    messagesListeners
  );

  return { state };
}
