import { RouteRecordRaw } from 'vue-router';

export const myProfileRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: 'my-profile',
    name: 'MyProfile',
    props: (route) => ({ isEditMode: route.name === 'EditMyProfile' }),
    component: () => import('./views/MyProfile.vue'),
    meta: {
      tabTitle: 'Мой профиль',
    },
    children: [
      {
        path: 'edit',
        name: 'EditMyProfile',
        component: () => import('./views/MyProfile.vue'),
      },
    ],
  },
];
