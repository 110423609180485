import { AxiosRequestConfig } from 'axios';

import { abortRequestService } from './AbortRequestService/AbortRequestService';

export function createAbortableAxiosRequest<T>(
  axiosRequest: (requestConfig: AxiosRequestConfig) => Promise<T>
): (requestConfig: AxiosRequestConfig) => Promise<T> {
  // todo: It doesn't work for complicated requests.
  // fix resolve request key
  const requestKey = abortRequestService.popKey();

  return (requestConfig: AxiosRequestConfig) => {
    const controller = new AbortController();

    if (requestKey) {
      abortRequestService.registerAbortFn(requestKey, () => controller.abort());
    }

    return axiosRequest({
      ...requestConfig,
      signal: requestConfig.signal ? requestConfig.signal : controller.signal,
    });
  };
}
