import { RouteRecordRaw } from 'vue-router';

export const paymentHistoryRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: 'payment-history',
    name: 'PaymentHistory',
    component: () => import('./PaymentHistory.vue'),
    meta: {
      tabTitle: 'История платежей',
    },
  },
];
