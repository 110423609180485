import { App, Plugin } from 'vue';

import { noop } from '@smartmed/utility/functions';

import { ANALYTICS_TOKEN } from '@/libs/Analytics/providers/analytics.token';

import { AnalyticsConnector } from '../providers/AnalyticsConnector.type';
import { AnalyticsEvent } from '../types/AnalyticsEvent';
import { GoogleAnalyticEvent } from '../types/GoogleAnalyticEvent';
import { GoogleLayer } from '../types/GoogleLayer';
import { YandexAnalyticEvent } from '../types/YandexAnalyticEvent';

const GOOGLE_TAG_ID = 'G-YNY19NRC2R';
const YANDEX_ID = 1096038;
const YANDEX_HANDLER_KEY = `yaCounter${YANDEX_ID}`;

class EventEmitter {
  private readonly events: Record<string, Array<(data: any) => void>> = {};

  $on(eventName: string, fn: (data: any) => void) {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }

    this.events[eventName].push(fn);

    return () => {
      this.events[eventName] = this.events[eventName].filter(
        (eventFn) => fn !== eventFn
      );
    };
  }

  $emit<T>(eventName: string, data: T) {
    const event = this.events[eventName];

    if (event) {
      event.forEach((fn) => fn(data));
    }
  }
}

const emitter = new EventEmitter();
const metrikaConfig = {
  accurateTrackBounce: true,
  clickmap: true,
  trackLinks: true,
  webvisor: true,
};

function loadScript(params: { id: string; src: string }) {
  return new Promise((resolve, reject) => {
    const emitKey = params.id + 'scriptLoaded';

    if (document.getElementById(params.id)) {
      emitter.$on(emitKey, resolve);

      return;
    }

    const head = document.head || document.getElementsByTagName('head')[0];
    const script = document.createElement('SCRIPT');

    script.setAttribute('src', params.src);
    script.setAttribute('async', 'true');
    script.setAttribute('defer', '');
    script.setAttribute('charset', 'utf-8');
    script.setAttribute('id', params.id);

    head.appendChild(script);

    script.onload = () => {
      emitter.$emit(emitKey, null);

      resolve(null);
    };

    script.onerror = reject;
  });
}

interface DataLayer extends Record<string, any> {
  event?: string;
}

declare global {
  interface Window {
    dataLayer?: DataLayer[];
    gtag?: (...args: unknown[]) => void;
  }
}

export const AnalyticsPlugin: Plugin<{ enabled: boolean }> = {
  install(app: App<any>, options: { enabled: boolean }) {
    const sendEventToGoogle = (event: GoogleAnalyticEvent) => {
      if (!window.dataLayer || !window.gtag) {
        initializeGoogleAnalytics();
      }

      window.gtag!('event', event.eventType, {
        send_to: GOOGLE_TAG_ID,
        ...event.payload,
      });
    };

    const addGoogleLayer = ({ payload }: GoogleLayer) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(payload);
    };

    const sendEventToYandex = (event: YandexAnalyticEvent) => {
      const handler = (window as any)[YANDEX_HANDLER_KEY] || {
        reachGoal: noop,
      };

      handler.reachGoal(event.eventType);
    };

    const analyticsConnector: AnalyticsConnector = {
      sendEvent: (event: AnalyticsEvent) => {
        if (event.google instanceof GoogleAnalyticEvent) {
          sendEventToGoogle(event.google);
        }

        if (event.yandex instanceof YandexAnalyticEvent) {
          sendEventToYandex(event.yandex);
        }

        if (event.googleLayer instanceof GoogleLayer) {
          addGoogleLayer(event.googleLayer);
        }
      },
    };

    app.provide(ANALYTICS_TOKEN, analyticsConnector);

    const initializeGoogleAnalytics = () => {
      window.dataLayer = window.dataLayer || [];

      window.gtag = function () {
        // eslint-disable-next-line
        window.dataLayer!.push(arguments);
      };

      window.gtag('js', new Date());
      window.gtag('config', GOOGLE_TAG_ID, {
        linker: {
          domains: ['medsi.ru'],
          accept_incoming: true,
        },
      });
    };

    const initializeYandexAnalytics = () => {
      const yandexInstance = (window as any)['Ya'];

      let metrika: any = noop;

      if (yandexInstance && yandexInstance.Metrika2) {
        metrika = new yandexInstance.Metrika2({
          ...metrikaConfig,
          id: YANDEX_ID,
        });
      }

      (window as any)[YANDEX_HANDLER_KEY] = metrika;
    };

    const initialize = async () => {
      if (!options.enabled) {
        return;
      }

      // those scripts may be blocked by adblockers
      loadScript({
        id: 'smed-google-analytics',
        src: `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_TAG_ID}`,
      })
        .then(() => {
          initializeGoogleAnalytics();
        })
        .catch(() => {
          console.error('Google Analytics script not loaded');
        });

      loadScript({
        id: 'smed-yandex-analytics',
        src: 'https://mc.yandex.ru/metrika/tag.js',
      })
        .then(() => {
          initializeYandexAnalytics();
        })
        .catch(() => {
          console.error('Yandex Analytics script not loaded');
        });
    };

    initialize();
  },
};
