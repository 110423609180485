import { RouteRecordRaw } from 'vue-router';

export const appDeletionInfo: ReadonlyArray<RouteRecordRaw> = [
  {
    path: '/app-deletion-info',
    name: 'AppDeletionInfoRoot',
    component: () => import('@/libs/Layouts/SimpleLayout.vue'),
    meta: {
      allowUnauth: true,
    },
    redirect: {
      name: 'AppDeletionInfoAndroid',
    },
    children: [
      {
        path: 'android',
        name: 'AppDeletionInfoAndroid',
        component: () => import('./views/Root.vue'),
        meta: {
          allowUnauth: true,
        },
      },
    ],
  },
];
