import { computed, ref } from 'vue';

import { defineStore, storeToRefs } from 'pinia';

import { usePatientsStore } from '@/libs/Patients/usePatientsStore';
import { useUserInfoStore } from '@/libs/User/useUserInfoStore';

import { useResetOnLogout } from '../Authorization/use/useResetOnLogout';
import { featureToggleService } from './http/FeatureToggle.service';
import { Feature } from './types/Feature.enum';
import { FeaturesResponse } from './types/Feature.types';
import { findGraphPath } from './types/findGraphPath';
import { getBlockedDescription } from './types/getBlockedDescription';
import { isFeatureAvailable } from './types/isFeatureAvailable';

export const useFeatureToggleStore = defineStore(
  'useFeatureToggleStore',
  () => {
    const patientsStore = usePatientsStore();
    const { activePatient } = storeToRefs(patientsStore);

    const userInfoStore = useUserInfoStore();
    const { info } = storeToRefs(userInfoStore);

    const loading = ref<boolean | null>(null);
    const features = ref<FeaturesResponse>([]);

    const reset = () => {
      loading.value = null;
      features.value = [];
    };

    useResetOnLogout(reset);

    const blockedDescription = (name: Feature) =>
      computed(() => {
        const _loading = loading.value;

        if (_loading !== false) {
          return null;
        }

        const _features = features.value;
        const user = activePatient.value;
        const path = findGraphPath(name);

        return getBlockedDescription(path, _features, user);
      });

    const featureAvailable = (name: Feature) =>
      computed(() => {
        const _loading = loading.value;

        if (_loading !== false) {
          return false;
        }

        const _features = features.value;
        const user = info.value;
        const path = findGraphPath(name);

        return isFeatureAvailable(path, _features, user);
      });

    const load = () => {
      loading.value = true;

      return featureToggleService
        .load()
        .catch(() => [])
        .then((r) => {
          features.value = r;
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      loading,
      features,

      load,

      blockedDescription,
      featureAvailable,
    };
  }
);
