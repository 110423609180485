import { RouteRecordRaw } from 'vue-router';

import { signInRoute } from './views/SignIn/route';
import { signUpRoute } from './views/SignUp/route';

export const authorizationRoute: ReadonlyArray<RouteRecordRaw> = (
  [] as ReadonlyArray<RouteRecordRaw>
)
  .concat(signInRoute)
  .concat(signUpRoute);
