import { AxiosInstance } from 'axios';

export type InterceptorFn = (
  interceptor: AxiosInstance['interceptors']
) => void;

export function setupInterceptors(
  axios: AxiosInstance,
  interceptors: ReadonlyArray<InterceptorFn>
) {
  interceptors.forEach((interceptor) => interceptor(axios.interceptors));
}
