import { AxiosRequestConfig } from 'axios';

import { InterceptorFn } from '@/libs/Http/setupInterceptors';

import { PATIENT_ID_SKIP_HEADER } from './patientIdSkipHeader';

export function patientIdInterceptor(): InterceptorFn {
  return (interceptors) => {
    interceptors.request.use((config: AxiosRequestConfig) => {
      const patientId = localStorage.getItem('__smed-patient-id');
      const headers = config.headers || {};

      if (patientId && !headers.PatientId) {
        config.headers = {
          ...headers,
          PatientId: patientId,
        };
      }

      if (headers.PatientId === PATIENT_ID_SKIP_HEADER) {
        delete (config.headers || {}).PatientId;
      }

      return config;
    });
  };
}
