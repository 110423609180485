import { RouteRecordRaw } from 'vue-router';

import { guardsBuilder } from '@/libs/Http/guardsBuilder';

import { editFamilyMemberGuard } from './guards/Family/editFamilyMemberGuard';

export const familyRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: 'family',
    name: 'Family',
    component: () => import('./views/Family.vue'),
    meta: {
      tabTitle: 'Пациенты',
    },
    children: [
      {
        path: 'add',
        name: 'AddFamilyMember',
        component: () => import('./views/AddFamilyMember.vue'),
      },
      {
        path: 'edit/:id',
        name: 'EditFamilyMember',
        beforeEnter: guardsBuilder([editFamilyMemberGuard], '/profile/family'),
        component: () => import('./views/EditFamilyMember.vue'),
        props: (route) => ({ userId: Number(route.params.id) }),
      },
    ],
  },
];
