import { noop } from '@smartmed/utility/functions';

export class NewAppVersionService {
  private newVersionCallback = noop;

  needNewVersion() {
    this.newVersionCallback();
  }

  registerNewVersionCallback(cb: () => void) {
    this.newVersionCallback = cb;
  }
}

export const newAppVersionService = new NewAppVersionService();
