import { LocationQueryValue, RouteRecordRaw } from 'vue-router';

function castToNumOrUndefined(
  value: LocationQueryValue | LocationQueryValue[]
) {
  return typeof value === 'string' && !Number.isNaN(Number(value))
    ? Number(value)
    : undefined;
}

export const invoicesRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: 'invoices',
    name: 'InvoiceList',
    redirect: { name: '_InvoiceRoot' },
    component: () => import('@/libs/Layouts/SimpleLayout.vue'),
    children: [
      {
        path: '',
        name: '_InvoiceRoot',
        component: () => import('./views/InvoiceList/InvoiceList.vue'),
        meta: {
          tabTitle: 'Мои счета',
        },
      },
      {
        path: ':id',
        name: 'Invoice',
        component: () => import('./views/Invoice/Invoice.vue'),
        meta: {
          tabTitle: 'Оплата счёта',
        },
      },
      {
        path: 'n/:receptionId',
        name: 'ReceptionInvoice',
        component: () => import('./views/Invoice/ReceptionInvoiceRoot.vue'),
        meta: {
          tabTitle: 'Оплата счёта',
        },
        props: (route) => ({
          receptionId: route.params.receptionId,
          limit: castToNumOrUndefined(route.query.limit),
          interval: castToNumOrUndefined(route.query.interval),
        }),
      },
    ],
  },
];
