export enum AgeLevel {
  /** Неизвестная группа */
  unspecified = 0,

  /** до 18 лет */
  child = 1,

  /** от 18 (включительно) */
  adult = 2,

  /** для всех возрастов */
  any = 3,
}
