import { RouteRecordRaw } from 'vue-router';

export const medicalCardRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: '/medical-card',
    name: 'MedicalCardRoot',
    components: {
      default: () => import('./views/MedicalCardRoot.vue'),
      submenu: () =>
        import(
          '@/libs/Patients/components/NavigationPatients/NavigationPatients.vue'
        ),
    },
    children: [
      {
        path: '',
        redirect: { name: 'MedicalCardHistory' },
        name: 'MedicalCard',
        component: () => import('./views/MedicalCard.vue'),
        meta: {
          title: 'Медкарта',
        },
        children: [
          {
            path: 'history',
            name: 'MedicalCardHistory',
            component: () => import('./views/History/History.vue'),
            meta: {
              title: 'Медкарта',
              tabTitle: 'Медкарта: Записи',
            },
          },
          {
            path: 'documents',
            name: 'MedicalCardDocuments',
            component: () => import('./views/Documents/Documents.vue'),
            meta: {
              title: 'Медкарта',
              tabTitle: 'Медкарта: Документы',
            },
          },
          {
            path: 'referrals',
            name: 'Referrals',
            component: () => import('./views/Referrals/views/ReferralsV2.vue'),
            meta: {
              title: 'Медкарта',
              tabTitle: 'Медкарта: Список направлений',
            },
          },
          {
            path: 'purpose',
            name: 'Purpose',
            component: () => import('./views/Purpose/views/Purpose.vue'),
            meta: {
              title: 'Медкарта',
              tabTitle: 'Медкарта: Список назначений',
            },
          },
          {
            path: 'home-call',
            name: 'HomeCall',
            component: () => import('./views/HomeCall/views/HomeCall.vue'),
            meta: {
              title: 'Медкарта',
              tabTitle: 'Медкарта: Список вызовов на дом',
            },
          },
        ],
      },
      {
        path: 'documents/:id',
        name: 'MedicalCardDocument',
        component: () => import('./views/DocumentItem/DocumentItem.vue'),
        meta: {
          title: 'Документ',
          tabTitle: 'Медкарта: Документ',
        },
        props: (route) => ({ id: Number(route.params.id) }),
      },
      {
        path: 'history/note/:id',
        name: 'MedicalCardNoteDescription',
        component: () => import('./views/MedicalCardNoteDescription.vue'),
        meta: {
          tabTitle: 'Медкарта: Описание записи',
        },
      },
      {
        path: 'history/n/note/:uuid',
        name: 'ReceptionView',
        component: () => import('./views/ReceptionView/ReceptionView.vue'),
        meta: {
          tabTitle: 'Медкарта: Описание записи',
        },
        props: (route) => ({ uuid: route.params.uuid }),
      },
      {
        path: 'referrals/:id',
        name: 'ReferralNote',
        component: () => import('./views/Referrals/views/ReferralV2Item.vue'),
        props: (route) => ({ id: route.params.id }),
        meta: {
          tabTitle: 'Медкарта: Направление',
        },
      },
      {
        path: 'purpose/:id',
        name: 'PurposeNote',
        component: () => import('./views/Purpose/views/PurposeNote.vue'),
        props: (route) => ({ id: route.params.id }),
        meta: {
          tabTitle: 'Медкарта: Назначение',
        },
      },

      {
        path: 'home-call/:id',
        name: 'HomeCallNote',
        component: () => import('./views/HomeCall/views/HomeCallNote.vue'),
        props: (route) => ({ id: route.params.id }),
        meta: {
          tabTitle: 'Медкарта: Вызов на дом',
        },
      },
    ],
  },
];
