import axios from 'axios';

import { ResolvedResponse } from '@/libs/Http/ResolvedResponse.type';
import { InterceptorFn } from '@/libs/Http/setupInterceptors';

import { CustomAxiosRequestConfig } from './CustomAxiosRequestConfig.interface';

const statusInfo = {
  status: 'SUCCESS',
  infoMessages: [
    {
      code: 'SUCCESS',
      descr: "Неопределенное 'Хорошо'.",
      paramName: null,
    },
  ],
};

export function responseInterceptor(): InterceptorFn {
  return (interceptors) => {
    interceptors.response.use(
      (response) => {
        const config = response.config as CustomAxiosRequestConfig;

        if (config.isSmartmed) {
          response.data = <
            ResolvedResponse<any, { isSmartmed: true }>['payload']
          >{
            data: response.data,
            statusInfo,
          };
        }

        return {
          status: response.status,
          success: true,
          payload: response.data,
        };
      },
      (error) => {
        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }

        const payload = error.response?.data || {
          message:
            error.toJSON && error.toJSON().message === 'Network Error'
              ? 'Нет интернета'
              : (error.toJSON && error.toJSON().message) || 'Ошибка',
        };

        return {
          status: error.response?.status,
          success: false,
          payload: payload,
        };
      }
    );
  };
}
