import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

export function medsiSmartmedTransitionGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const { type, clinic, doctor, examCode, services, date, totalAmount } =
    to.query;

  if (type === 'offline') {
    const shouldOpenDoctor = !!doctor;

    if (shouldOpenDoctor) {
      next({
        name: 'MedsiDoctorDetail',
        query: {
          doctorMisId: doctor as string,
        },
      });

      return;
    }

    next({
      name: 'MedsiAppointment',
      query: {
        ...to.query,
        type: undefined,
        examCode: examCode as string,
      },
    });

    return;
  }

  if (type === 'smartlab') {
    const hasServices = !!services;

    if (hasServices && clinic && date) {
      next({
        name: 'MedsiAnalysis',
        query: {
          services: (services as string).split(','),
          date: date as string,
          totalAmount: totalAmount as string,
          clinic: clinic as string,
        },
      });

      return;
    }

    next({ name: 'Analysis' });

    return;
  }

  next();
}
