import { RouteRecordRaw } from 'vue-router';

export const cardsRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: 'cards',
    name: 'CardsList',
    component: () => import('./views/CardsList.vue'),
    meta: {
      tabTitle: 'Мои банковские карты',
    },
    children: [
      {
        path: 'add',
        name: 'AddCard',
        component: () => import('./views/AddCard.vue'),
      },
    ],
  },
];
