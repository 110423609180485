import { RouteRecordRaw } from 'vue-router';

export const promocodesRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: 'promocodes',
    name: 'Promocodes',
    component: () => import('./views/Promocodes.vue'),
    meta: {
      tabTitle: 'Промокоды',
    },
  },
];
