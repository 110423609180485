import { httpClient } from '@/libs/Http';
import { Response } from '@/libs/Http/Response';
import { UpdateInfoRequest } from '@/libs/User/types/Info/UpdateInfoRequest.type';
import { UpdateInfoResponse } from '@/libs/User/types/Info/UpdateInfoResponse.type';
import {
  UserInfoType,
  UserInfoV3Type,
} from '@/libs/User/types/Info/UserInfo.type';
import { UserInfoForEdit } from '@/libs/User/types/Info/UserInfoForEdit.type';
import { UserPassport } from '@/libs/User/types/Info/UserPassport.type';
import { UserPassportRequest } from '@/libs/User/types/Info/UserPassportRequest.type';

export class UserInfoRepository {
  async get(): Promise<Response<UserInfoType>> {
    return new Response(await httpClient.get('/users/info/v2'));
  }

  getInfoForEdit(userId: number): Promise<Response<UserInfoForEdit>> {
    return httpClient
      .get<UserInfoForEdit>('/users/infoforedit', {
        params: {
          userId,
        },
      })
      .then((response) => new Response(response));
  }

  updateInfo(
    request: UpdateInfoRequest
  ): Promise<Response<UpdateInfoResponse>> {
    return httpClient
      .post<UpdateInfoResponse, UpdateInfoRequest>(
        '/users/fillparameters/v4',
        request
      )
      .then((response) => new Response(response));
  }

  getUserInfo(): Promise<Response<UserInfoV3Type>> {
    return httpClient
      .get<UserInfoV3Type>('/users/info/v3')
      .then((response) => new Response(response));
  }

  getPassport(): Promise<Response<UserPassport>> {
    return httpClient
      .get<UserPassport>('/users/passport')
      .then((response) => new Response(response));
  }

  updatePassport(
    request: UserPassportRequest
  ): Promise<Response<UserPassport>> {
    return httpClient
      .post<
        UserPassport,
        UserPassportRequest & { docType: UserPassport['docType'] }
      >('/users/fillpassport', {
        ...request,
        docType: 0,
      })
      .then((response) => new Response(response));
  }
}
