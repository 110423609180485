import { defineAsyncComponent } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { guardsBuilder } from '@/libs/Http/guardsBuilder';

import { depositHistoryDetailGuard } from './guards/Deposits/depositHistoryDetailGuard';
import { depositRefillGuard } from './guards/Deposits/depositRefillGuard';

export const depositsRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: 'deposits',
    name: 'Deposits',
    redirect: { name: 'DepositsRoot' },
    component: () => import('./DepositsRoot.vue'),
    meta: {
      layout: defineAsyncComponent(
        () => import('@/libs/Layouts/LayoutDefault.vue')
      ),
      tabTitle: 'Мой кошелёк',
    },
    children: [
      {
        path: '',
        name: 'DepositsRoot',
        component: () => import('./views/Deposits.vue'),
      },
      {
        path: ':id',
        name: 'DepositDetail',
        component: () => import('./views/DepositDetail.vue'),
        props: (route) => ({
          id: route.params.id,
        }),
      },

      {
        path: ':id/refill',
        name: 'DepositRefill',
        component: () => import('./views/DepositRefill.vue'),
        props: (route) => ({
          id: route.query.id,
          userId: route.query.userId,
          sum: route.query.sum,
        }),
        beforeEnter: guardsBuilder(
          [depositRefillGuard],
          '/profile/wallet/deposits'
        ),
      },
      {
        path: ':id/:paymentId',
        name: 'DepositHistoryDetail',
        beforeEnter: guardsBuilder(
          [depositHistoryDetailGuard],
          '/profile/wallet/deposits'
        ),
        component: () => import('./views/DepositHistoryDetail.vue'),
      },
    ],
  },
];
