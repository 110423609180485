import { RouteRecordRaw } from 'vue-router';

import {
  loyalityHistoryGuard,
  loyalitySmsConfirmGuard,
} from './guards/loyality.guard';

export const loyalityRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: 'loyality',
    name: 'Loyality',
    component: () => import('./views/Loyality.vue'),
    redirect: { name: 'LoyalityHistory' },
    meta: {
      tabTitle: 'Программа лояльности',
    },
    children: [
      {
        path: 'activation',
        name: 'LoyalityActivation',
        component: () => import('./views/Activation/Activation.vue'),
      },
      {
        path: 'gift',
        name: 'LoyalityGift',
        beforeEnter: loyalityHistoryGuard,
        component: () => import('./views/Gift/Gift.vue'),
      },
      {
        path: 'more',
        name: 'LoyalityMore',
        component: () => import('./views/More/More.vue'),
      },
      {
        path: 'transfer',
        name: 'LoyalityTransfer',
        beforeEnter: loyalityHistoryGuard,
        component: () => import('./views/Transfer/Transfer.vue'),
      },
      {
        path: 'confirm',
        name: 'LoyalitySmsConfirm',
        beforeEnter: loyalitySmsConfirmGuard,
        component: () => import('./views/SmsConfirm/SmsConfirm.vue'),
        props: (route) => ({
          userId: Number(route.query.userId),
          bonuses: Number(route.query.bonuses),
          phone: route.query.phone,
          title: route.query.title,
        }),
      },
      {
        path: '',
        name: 'LoyalityHistory',
        beforeEnter: loyalityHistoryGuard,
        component: () => import('./views/History/History.vue'),
      },
    ],
  },
];
