import { Feature, FeaturesGraph } from './Feature.enum';

export function findGraphPath(name: Feature): ReadonlyArray<Feature> {
  const parents = FeaturesGraph[name];

  if (parents.length === 0) {
    return [name];
  }

  const result = parents.map((feature) => findGraphPath(feature));

  return [name].concat(...result);
}
