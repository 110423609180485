import { App, readonly, Ref, ref } from 'vue';

import jsSHA from 'jssha';

import type { UserInfoType } from '@/libs/User/types/Info/UserInfo.type';
import { getFormatDateByParams } from '@/shared/Utility/Date/getFormatDateByParams';

type FieldsType = {
  id: string;
  display_name: string;
  phone: string;
  email?: string;
  profile_url?: string;
  avatar_url?: string;
  login?: string;
  comment?: string;
  info?: string;
  city?: string;
};

declare global {
  interface Window {
    webim: {
      accountName: string;
      domain: string;
      location: string;
      api: {
        chat: {
          close: () => void;
          isActive: () => void;
          setData: (data: any) => void;
          start: (params: any) => void;
        };
        invitation: {
          start: () => void;
        };
        button: { add: () => void };
      };
    };
    webim_visitor: {
      fields: FieldsType;
      expires: number;
      hash: string;
    };
  }
}

export const WEBIM_CHAT_API_TOKEN = Symbol();

export interface IWebimChatPlugin {
  init: (user: UserInfoType) => Promise<void>;
  initialized: Ref<boolean>;
  destroy: () => void;
}

const CHAT_SCRIPT_ACCOUNT_NAME = 'medsiru001';
const CHAT_SCRIPT_LOCATION = 'smartmed_pro';
const CHAT_SCRIPT_ID = 'smed-chat-script';
const CHAT_SCRIPT_SRC = 'https://chat.smartmed.pro/js/button.js';

export const ChatWidgetPlugin = {
  install(app: App, options: { enabled: boolean }) {
    const initialized = ref(false);

    const loadScript = async (params: { id: string; src: string }) => {
      if (document.getElementById(params.id)) {
        return;
      }

      const head = document.head || document.getElementsByTagName('head')[0];
      const script = document.createElement('SCRIPT');

      script.setAttribute('src', params.src);
      script.setAttribute('async', 'true');
      script.setAttribute('id', params.id);

      head.appendChild(script);
    };

    const getUserAuthorizedChatFields = (user: UserInfoType): FieldsType => {
      const dob = user.birthday
        ? getFormatDateByParams(user.birthday, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        : 'Не указано';

      return {
        id: user.userId.toString() || 'Не указано',
        display_name: user.fio || 'Не указано',
        email: user.email || 'Не указано',
        phone: user.phone || 'Не указано',
        comment: `День рождения: ${dob}, город ${user.city}`,
        city: user.city,
      };
    };

    const createValueString = (
      fields: FieldsType,
      unixTimestamp?: number
    ): string =>
      Object.keys(fields)
        .sort()
        .reduce((acc, key) => acc + fields[key as keyof FieldsType], '') +
      (unixTimestamp ? unixTimestamp.toString() : '');

    const computeHash = (valueString: string): string => {
      const shaObj = new jsSHA('SHA-256', 'TEXT', {
        hmacKey: { value: GLOBAL_CONFIG.WEBIM_PRIVATE_KEY, format: 'TEXT' },
      });

      shaObj.update(valueString);

      return shaObj.getHMAC('HEX');
    };

    const init = async (user: UserInfoType) => {
      if (!options.enabled) {
        return;
      }

      initialized.value = true;
      window.webim = {
        ...(window.webim || {}),
        accountName: CHAT_SCRIPT_ACCOUNT_NAME,
        domain: GLOBAL_CONFIG.CHAT_SCRIPT_DOMAIN,
        location: CHAT_SCRIPT_LOCATION,
      };

      const currentDate = new Date();

      currentDate.setHours(currentDate.getHours() + 1);
      const unixTimestamp = Math.floor(currentDate.getTime() / 1000);

      const fields = getUserAuthorizedChatFields(user);
      const valueString = createValueString(fields, unixTimestamp);
      const hash = computeHash(valueString);

      window.webim_visitor = {
        fields,
        expires: unixTimestamp,
        hash: hash,
      };

      try {
        const chatToggle = document.querySelector(
          '[data-webim-type="dropdown-toggle"]'
        ) as HTMLElement;

        if (chatToggle) {
          chatToggle.style.display = 'flex';
        }

        await loadScript({
          id: CHAT_SCRIPT_ID,
          src: CHAT_SCRIPT_SRC,
        });
      } catch (err) {
        console.error('Ошибка загрузки скрипта чата', err);
      }
    };

    const destroy = () => {
      initialized.value = false;

      const chatToggle = document.querySelector(
        '[data-webim-type="dropdown-toggle"]'
      ) as HTMLElement;

      if (chatToggle) {
        chatToggle.style.display = 'none';
      }

      window.webim?.api?.chat?.close?.();
      window.webim_visitor = {} as Window['webim_visitor'];
    };

    const instance: IWebimChatPlugin = {
      init,
      initialized: readonly(initialized),
      destroy,
    };

    app.provide(WEBIM_CHAT_API_TOKEN, instance);
  },
};
