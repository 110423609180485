import { RouteRecordRaw } from 'vue-router';

export const homeRoute: ReadonlyArray<RouteRecordRaw> = [
  {
    path: '',
    name: 'Home',
    components: {
      default: () => import('./views/Home.vue'),
      OutContainerBottom: () => import('./components/DownloadApp.vue'),
    },
    meta: {
      tabTitle: 'Личный кабинет пациента',
    },
    props: {
      default: (route) => ({
        showAuthPopup: route.query.showAuthPopup === '1',
      }),
    },
  },
];
