import { SignInStatus } from '@/libs/Authorization/types/SignInStatus.enum';
import { ServerPayload } from '@/libs/Http/Response';

const supportedCodes = Object.values(SignInStatus);

export function getUserRegistrationStatus(response: {
  payload: ServerPayload<null>;
}): SignInStatus | null {
  const messages = response.payload.statusInfo.infoMessages;

  if (messages && messages.length !== 0) {
    const code = messages[0].code as SignInStatus;

    return supportedCodes.includes(code) ? code : null;
  }

  return null;
}
