import { getTimezoneOffset } from './getTimezoneOffset';

const ONE_MINUTE_IN_MS = 60000;

export function getLocalDateTime(date: Date): string {
  const offset = getTimezoneOffset();
  const localTimestamp = date.getTime() + offset * ONE_MINUTE_IN_MS;

  return new Date(localTimestamp).toISOString().replace(/Z$/, '');
}

export function getLocalDateTimeWithOffset(): string {
  const offset = getTimezoneOffset();

  const prefix = offset < 0 ? '-' : '+';
  const hours = `${offset / 60}`.padStart(2, '0');
  const minutes = `${offset % 60}`.padStart(2, '0');
  const fixed = getLocalDateTime(new Date()).split('.')[0];

  return fixed + `${prefix}${hours}:${minutes}`;
}
