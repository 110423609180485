import { authTokenInterceptor } from '@/libs/Authorization/http/authToken.interceptor';
import { getApplicationHeaders } from '@/libs/Core';
import { deployInterceptor } from '@/libs/Core/OutdatedClient/http/deploy.interceptor';
import { newAppVersionService } from '@/libs/Core/OutdatedClient/http/NewAppVersion.service';
import { blobTypeInterceptor } from '@/libs/File/http/blobType.interceptor';
import { patientIdInterceptor } from '@/libs/Patients/http/patientId.interceptor';
import router from '@/router';

import { Axios } from './Axios';
import { Http } from './Http';
import { responseInterceptor } from './response.interceptor';
import { setupInterceptors } from './setupInterceptors';
import { urlByConfigInterceptor } from './urlByConfig.interceptor';

const axiosConfig = {
  headers: getApplicationHeaders(),
};

const axios = new Axios(axiosConfig);

const httpClient = new Http(axios);

export { Http } from './Http';
export { httpClient };

const auth = authTokenInterceptor(() => router);

// note: Порядок важен, не изменяйте его если не знаете что делаете
setupInterceptors(axios.getInstance(), [
  blobTypeInterceptor(),
  auth,
  patientIdInterceptor(),
  urlByConfigInterceptor(),
  deployInterceptor(newAppVersionService),
  responseInterceptor(),
]);

export const authInterceptor = auth;
