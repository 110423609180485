import { RouteLocationNormalized } from 'vue-router';

export function appointmentSubmitGuard(to: RouteLocationNormalized): boolean {
  const { time } = to.query;

  if (!Number(time)) {
    return false;
  }

  return true;
}
