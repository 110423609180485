import { RouteRecordRaw } from 'vue-router';

import { unauthzoneGuard } from '@/libs/Authorization/guards/Unauthzone/UnauthzoneGuard';
import { guardsBuilder } from '@/libs/Http/guardsBuilder';

export const signInRoute: ReadonlyArray<RouteRecordRaw> = [
  {
    path: '/sign-in',
    component: () => import('../Authorization.vue'),
    meta: {
      allowUnauth: true,
      savedPosition: true,
      tabTitle: 'Новый личный кабинет Медси. Вход',
    },
    beforeEnter: guardsBuilder([unauthzoneGuard]),
    children: [
      {
        path: '',
        name: 'SignIn',
        component: () => import('./views/Phone.vue'),
      },
      {
        path: 'confirm',
        name: 'SignInConfirm',
        component: () => import('./views/Confirm.vue'),
        props: (route) => ({
          from: route.query.from,
        }),
      },
      {
        path: '',
        redirect: '/sign-in',
      },
    ],
  },
];
