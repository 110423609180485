import { Connection } from './Connection.interface';
import { CustomAxiosRequestConfig } from './CustomAxiosRequestConfig.interface';
import { ResolvedResponse } from './ResolvedResponse.type';

export class Http {
  constructor(private client: Connection) {}

  get<TResponse>(
    url: string,
    config?: undefined
  ): Promise<ResolvedResponse<TResponse, CustomAxiosRequestConfig>>;
  get(
    url: string,
    config?: CustomAxiosRequestConfig & {
      responseType: 'blob';
      isSmartmed?: undefined;
    }
  ): Promise<ResolvedResponse<never, { responseType: 'blob' }>>;
  get<TResponse>(
    url: string,
    config?: CustomAxiosRequestConfig & {
      isSmartmed?: undefined;
      responseType?: undefined;
    }
  ): Promise<ResolvedResponse<TResponse, { isSmartmed: false }>>;
  get<TResponse>(
    url: string,
    config?: CustomAxiosRequestConfig & { isSmartmed: true }
  ): Promise<ResolvedResponse<TResponse, { isSmartmed: true }>>;
  get(url: string, config: any): Promise<any> {
    return this.client.request(url, 'get', null, config);
  }

  post<TResponse, TData>(
    url: string,
    data?: TData,
    config?: CustomAxiosRequestConfig & { isSmartmed?: undefined }
  ): Promise<ResolvedResponse<TResponse, { isSmartmed: false }>>;
  post<TResponse, TData>(
    url: string,
    data?: TData,
    config?: CustomAxiosRequestConfig & { isSmartmed: true }
  ): Promise<ResolvedResponse<TResponse, { isSmartmed: true }>>;
  post<TResponse, TData>(url: string, data?: any, config?: any): Promise<any> {
    return this.client.request(url, 'post', data, config);
  }

  put<TResponse, TData>(
    url: string,
    data?: TData,
    config?: CustomAxiosRequestConfig & { isSmartmed?: undefined }
  ): Promise<ResolvedResponse<TResponse, { isSmartmed: false }>>;
  put<TResponse, TData>(
    url: string,
    data?: TData,
    config?: CustomAxiosRequestConfig & { isSmartmed: true }
  ): Promise<ResolvedResponse<TResponse, { isSmartmed: true }>>;
  put<TResponse, TData>(url: string, data?: TData, config?: any): Promise<any> {
    return this.client.request(url, 'put', data, config);
  }

  delete<TResponse>(
    url: string,
    config?: CustomAxiosRequestConfig & { isSmartmed?: undefined }
  ): Promise<ResolvedResponse<TResponse, { isSmartmed: false }>>;
  delete<TResponse>(
    url: string,
    config?: CustomAxiosRequestConfig & { isSmartmed: true }
  ): Promise<ResolvedResponse<TResponse, { isSmartmed: true }>>;
  delete<TResponse>(url: string, config?: any): Promise<any> {
    return this.client.request(url, 'delete', null, config);
  }
}
