import { useAlerts } from '@smartmed/ui/use/alerts';

export const showNotifierErrors = (
  errors: ReadonlyArray<string>,
  service: ReturnType<typeof useAlerts>
): void => {
  if (errors && Array.isArray(errors)) {
    errors.forEach((message) => {
      service.show(message, { type: 'error' });
    });
  }
};
