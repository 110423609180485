import { RouteRecordRaw } from 'vue-router';

import { appDeletionInfo } from './AppDeletionInfo/route';
import { appointmentRoutes } from './Appointment/appointmentRoutes';
import { authorizationRoute } from './Authorization/route';
import { chronicsRoutes } from './Chronics/chronicsRoutes';
import { clinicsRoutes } from './Clinics/route';
import { doctorsRoutes } from './Doctors/route';
import { helpRoutes } from './Help/route';
import { homeRoute } from './Home/route';
import { medicalCardRoutes } from './MedicalCard/route';
import { onlineConsultationRoutes } from './OnlineConsultation/route';
// import { packagesRoutes } from './Packages/route';
import { profileRoutes } from './Profile/profile';

const dummy = [] as ReadonlyArray<RouteRecordRaw>;

export const authzoneRoutes = dummy
  .concat(appointmentRoutes)
  .concat(clinicsRoutes)
  .concat(doctorsRoutes)
  .concat(helpRoutes)
  .concat(homeRoute)
  .concat(medicalCardRoutes)
  .concat(onlineConsultationRoutes)
  // .concat(packagesRoutes)
  .concat(profileRoutes)
  .concat(chronicsRoutes)
  .concat(appDeletionInfo);

export const unauthzoneRoutes = dummy.concat(authorizationRoute);
