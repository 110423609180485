import { DTOFeatureToggle } from '@/libs/FeatureToggle/types/dto/DTOFeatureToggle';
import { httpClient } from '@/libs/Http';
import { pythonDtoMapper } from '@/libs/Http/pythonDtoMapper';

export class FeatureToggleRepository {
  getFeatures() {
    return httpClient
      .get<ReadonlyArray<DTOFeatureToggle>>(
        '/feature-toggle/feature-toggle/v1/feature/availability/web',
        {
          isSmartmed: true,
        }
      )
      .then(pythonDtoMapper);
  }
}
