import { httpClient } from '@/libs/Http';

import { DTOChatMessage } from './dto/message.dto';

export enum DTOChatState {
  Unspecified = 0,
  Created,
  Started,
  Stopped,
  Deactivated,
}

export type DTOChatInfo = {
  chatState: DTOChatState;
  lastMessageId: string;
  numberOfUsers: number;
};

export function useChatApi() {
  const getUnreadMessagesCount = (chatId: number | string) => {
    return httpClient.get<DTOChatInfo>(`/chat/getchatinfo?chatId=${chatId}`);
  };

  const getChatHistory = async (
    chatId: number | string,
    messagesCount: number,
    messageId?: string
  ) => {
    return await httpClient
      .get<DTOChatMessage[] | null>(
        `/chat/getchathistory?chatId=${chatId}&messagesCount=${messagesCount}&messageId=${messageId}`
      )
      .then((response) => {
        return response.success
          ? (response.payload as any as DTOChatMessage[] | null)
          : [];
      });
  };

  const getChatInfo = async (
    chatId: number | string
  ): Promise<DTOChatInfo | null> => {
    return (await httpClient
      .get(`/chat/getchatinfo?chatId=${chatId}`)
      .then(({ success, payload }) =>
        success ? payload : null
      )) as DTOChatInfo;
  };

  const sendMessage = (
    chatId: number | string,
    message: {
      seed: number;
      text: string;
      attachments: { fileId: number }[];
    }
  ) => {
    return httpClient.post('/chat/sendmessage', { ...message, chatId });
  };

  const downloadBlob = async (query: {
    chatId: string | number;
    userId: string | number;
    fileId: string | number;
  }): Promise<Blob> => {
    return (await httpClient
      .post(`/chat/file`, query, {
        responseType: 'blob',
      })
      .then(({ success, payload }) => (success ? payload : null))) as Blob;
  };

  const markMessageAsRead = (chatId: number | string, messageId: string) => {
    return httpClient
      .post('/chat/messageseen', { chatId, messageId })
      .then(({ success, payload }) =>
        success ? Promise.resolve(payload) : Promise.reject([])
      );
  };

  return {
    getUnreadMessagesCount,
    getChatHistory,
    getChatInfo,
    sendMessage,
    downloadBlob,
    markMessageAsRead,
  };
}
