import { RouteRecordRaw } from 'vue-router';

export const clinicsRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: '/clinics',
    name: 'ClinicsRoot',
    component: () => import('@/libs/Layouts/SimpleLayout.vue'),
    meta: {
      title: 'Клиники',
    },
    children: [
      {
        path: '',
        name: 'Clinics',
        component: () => import(`./views/Clinics.vue`),
        meta: {
          title: '',
          tabTitle: 'Клиники',
        },
      },
      {
        path: ':id',
        component: () => import(`./views/ClinicInfoSmartmed.vue`),
        children: [
          {
            path: '',
            name: 'ClinicInfoSmartmed',
            component: () => import(`./views/ClinicInfo.vue`),
            props: (route) => ({ id: route.params.id }),
            meta: {
              tabTitle: 'Профиль клиники',
            },
          },
        ],
      },
      {
        path: ':id/:locationId/:type',
        name: 'ClinicInfo',
        component: () => import(`./views/ClinicInfo.vue`),
        props: true,
        meta: {
          tabTitle: 'Профиль клиники',
        },
      },
    ],
  },
];
