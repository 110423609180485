<template>
  <popup>
    <div :class="$style.message">
      <p>Приложение было обновлено.</p>
      <p>Для корректной работы обновите страницу.</p>
    </div>

    <base-button @click="reloadPage">Обновить</base-button>
  </popup>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { BaseButton } from '@smartmed/ui/BaseButton';
import { Popup } from '@smartmed/ui/Popup';

export default defineComponent({
  components: {
    Popup,
    BaseButton,
  },
  setup() {
    const reloadPage = () => {
      location.reload();
    };

    return {
      reloadPage,
    };
  },
});
</script>

<style module lang="scss">
.message {
  @include smed-text_h5;

  text-align: center;
  margin-bottom: 25px;
}
</style>
