import { RouteRecordRaw } from 'vue-router';

export const chronicsRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: '/chronics',
    name: 'ChronicsRoot',
    component: () => import('./ChronicsRoot.vue'),
    meta: {
      tabTitle: 'Дневник здоровья',
    },
    children: [
      {
        path: '',
        name: 'Chronics',
        component: () => import(`./views/ChronicsRoot.vue`),
        meta: {
          title: '',
        },
      },
      {
        path: 'pills',
        name: 'PillsList',
        component: () => import(`./views/Pills/PillsView.vue`),
        meta: {
          title: '',
        },
      },
      {
        path: 'referrals',
        name: 'ReferralsList',
        component: () => import(`./views/Referrals/ReferralsList.vue`),
        meta: {
          title: '',
        },
      },
      {
        path: 'quizzes',
        name: 'Quizzes',
        component: () => import('@/libs/Layouts/SimpleLayout.vue'),
        meta: {
          title: '',
        },
        children: [
          {
            path: '',
            name: 'QuizzesList',
            component: () => import(`./views/Quizzes/Quizzes.vue`),
            meta: {
              title: '',
            },
          },
          {
            path: ':id',
            name: 'QuizView',
            component: () => import('./views/Quizzes/QuizView.vue'),
            props: (route) => ({
              id: route.params.id,
              timeSlot: route.query.timeSlot,
            }),
            meta: {
              tabTitle: 'Опрос',
            },
          },
        ],
      },
      {
        path: 'analytics',
        name: 'Analytics',
        component: () => import('@/libs/Layouts/SimpleLayout.vue'),
        meta: {
          title: '',
        },
        children: [
          {
            path: '',
            name: 'AnalyticsNav',
            component: () => import(`./views/Analytics/AnalyticsNav.vue`),
            meta: {
              title: '',
            },
          },
          {
            path: 'quizzes',
            name: 'AnalyticsQuizzes',
            component: () => import(`./views/Analytics/AnalyticsQuizzes.vue`),
            meta: {
              title: '',
            },
          },
          {
            path: 'quizzes/:id',
            name: 'AnalyticsQuizzesView',
            component: () =>
              import('./views/Analytics/AnalyticsQuizzesView.vue'),
            props: (route) => ({ id: route.params.id }),
            meta: {
              tabTitle: 'Результаты',
            },
          },
          {
            path: 'pills',
            name: 'AnalyticsPills',
            component: () => import(`./views/Analytics/AnalyticsPills.vue`),
            meta: {
              title: '',
            },
          },
          {
            path: 'pills/:id',
            name: 'AnalyticsPillView',
            component: () => import(`./views/Analytics/AnalyticsPillsView.vue`),
            props: (route) => ({ id: route.params.id }),
            meta: {
              title: '',
            },
          },
          {
            path: 'referrals',
            name: 'AnalyticsReferrals',
            component: () => import(`./views/Analytics/AnalyticsReferrals.vue`),
            meta: {
              title: '',
            },
          },
        ],
      },
      {
        path: 'chat',
        name: 'ChronicsChat',
        component: () => import(`./views/Chat/Chat.vue`),
        meta: {
          title: '',
        },
      },
    ],
  },
];
