<template>
  <root>
    <router-view />

    <app-update-popup v-if="isNeedUpdate" v-model="isNeedUpdate" />
    <a v-if="webimInitilized" class="webim_button" href="#" rel="webim">
      <img src="https://chat.smartmed.pro/button.php" border="0" />
    </a>
  </root>
</template>

<script lang="ts">
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  inject,
  onMounted,
  provide,
  ref,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { Root } from '@smartmed/ui/Root';
import {
  CUSTOM_ICONS_TOKEN,
  IS_POPUP_MOBILE_TOKEN,
  VALIDATION_ERRORS_TOKEN,
} from '@smartmed/ui/tokens';
import { useMedia } from '@smartmed/ui/use';
import { useAlerts } from '@smartmed/ui/use/alerts';
import { noop } from '@smartmed/utility/functions';
import { storeToRefs } from 'pinia';

import { useAuthorization } from '@/libs/Authorization/use/useAuthorization';
import { useChronics } from '@/libs/ChatChronics/use/useChronics';
import {
  IWebimChatPlugin,
  WEBIM_CHAT_API_TOKEN,
} from '@/libs/ChatScript/plugins/ChatScript.plugin';
import { useCityStore } from '@/libs/City/useCityStore';
import { setupCoreModule } from '@/libs/Core';
import { AppUpdatePopup } from '@/libs/Core/OutdatedClient/components/AppUpdatePopup';
import { newAppVersionService } from '@/libs/Core/OutdatedClient/http/NewAppVersion.service';
import { useFeatureToggleStore } from '@/libs/FeatureToggle/useFeatureToggleStore';
import { PHRASES } from '@/libs/Http/phrases';
import { useLoyalityInitialization } from '@/libs/Loyality';
import { setupGlobalChatHub } from '@/libs/MessagesHub';
import { showNotifierErrors } from '@/libs/Notifications/notifier/showNotifierErrors';
import { userInfoService } from '@/libs/User/http/UserInfo/UserInfo';
import { UserInfoType } from '@/libs/User/types/Info/UserInfo.type';
import { useUserInfoStore } from '@/libs/User/useUserInfoStore';
import { MediaValue } from '@/shared/BrowserApi/Media/mediaValue.enum';
import { LOGIN_VIA_QUERY_TOKEN } from '@/tokens/loginViaQuery.token';

import { IS_QUERY_SYS_IOS } from './tokens/isQuerySysIos.token';

export default defineComponent({
  components: {
    AppUpdatePopup,
    Root,
  },
  setup() {
    const _ = setupGlobalChatHub();

    setupCoreModule();

    const loyality = useLoyalityInitialization();
    const authService = useAuthorization();
    const alertsService = useAlerts();
    const { authTokenRef } = storeToRefs(authService);

    const route = useRoute();
    const router = useRouter();
    const chronics = useChronics();
    const userInfoStore = useUserInfoStore();
    const featureToggleStore = useFeatureToggleStore();

    const cityStore = useCityStore();
    const { cityName, cities } = storeToRefs(cityStore);

    const { computedSmallerThen } = useMedia();

    const token = computed(() => {
      return authTokenRef.value;
    });

    const isMobile = computedSmallerThen(MediaValue.SM);
    const loadingLoginViaQuery = ref(false);

    provide(LOGIN_VIA_QUERY_TOKEN, loadingLoginViaQuery);

    provide(CUSTOM_ICONS_TOKEN, {
      'alert-doc': defineAsyncComponent(
        () => import('./assets/svg/alert-doc.svg')
      ),
      'payment-receipt': defineAsyncComponent(
        () => import('./assets/svg/receipt.svg')
      ),
    });

    provide(IS_POPUP_MOBILE_TOKEN, isMobile);

    provide(VALIDATION_ERRORS_TOKEN, {
      required: PHRASES.required,
      not: PHRASES.required,
      requiredTrue: PHRASES.required,
    });

    const queryAccessToken = computed(() => {
      const val = router.currentRoute.value;

      return val.query.access_token;
    });

    const isQuerySysIos = computed(() => {
      const val = router.currentRoute.value;
      const isFromIos = decodeURIComponent(
        (val.query?.from as string) || ''
      ).includes('sys=ios');
      const isSysIos = val.query?.sys === 'ios';

      return isFromIos || isSysIos;
    });

    provide(IS_QUERY_SYS_IOS, isQuerySysIos);

    const loadFeatureToggle = () => {
      featureToggleStore.load();
    };

    const loadLoyality = () => {
      loyality.initialize();
    };

    const loadUserCity = async (queryCity: string | null) => {
      if (!queryCity) {
        cityStore.loadUserCity();

        return;
      }

      cityStore.loadCities();

      const _cities = cities.value;
      const findCityByQueryParams = _cities?.find(
        (city) => city.site?.toLowerCase() === queryCity.toLowerCase()
      );

      if (findCityByQueryParams) {
        cityStore.setCity(findCityByQueryParams);
      } else {
        cityStore.loadUserCity();
      }
    };

    const webimChat = inject(WEBIM_CHAT_API_TOKEN) as IWebimChatPlugin;
    const { init: initializeWebimChat } = webimChat;
    const webimInitilized = webimChat.initialized;

    const loadUserInfo = async () => {
      const { payload, success } = await userInfoService.get();

      if (success) {
        userInfoStore.set(payload.data);
        initializeWebimChat({
          ...payload.data,
          city: cityName.value,
        } as UserInfoType);
      } else {
        showNotifierErrors(payload.messages.common, alertsService);
      }
    };

    const loadChronicsAvailability = () => {
      chronics.getCachedHasAccess(true).then(noop).catch(noop);
    };

    watch(token, (value) => {
      if (value) {
        loadUserInfo();
        loadFeatureToggle();
        loadLoyality();
        loadChronicsAvailability();
      } else {
        chronics.clearHasChronicsAccess();
      }
    });

    const isNeedUpdate = ref(false);
    const reloadPage = () => {
      location.reload();
    };

    const init = async () => {
      if (token.value) {
        await loadUserInfo();

        loadFeatureToggle();
        loadLoyality();
        loadChronicsAvailability();
      }
    };

    onMounted(() => {
      const showNewVersionPopup = () => {
        isNeedUpdate.value = true;
      };

      newAppVersionService.registerNewVersionCallback(showNewVersionPopup);

      init();
    });

    watch(
      [token, () => route.query],
      ([_token, query]) => {
        if (_token) {
          loadUserCity((query.city as string) || null);
        }
      },
      {
        immediate: true,
      }
    );

    const loginViaQuery = (value: string) => {
      loadingLoginViaQuery.value = true;

      authService
        .loginViaQueryToken(value)
        .then((success) => {
          if (success) {
            const _route = router.currentRoute.value;
            const _from = _route.query.from;
            const _validFrom =
              typeof _from === 'string' && !!_from ? _from : null;

            const route = _validFrom
              ? decodeURIComponent(_validFrom)
              : { name: 'Home' };

            router.replace(route);
          }
        })
        .finally(() => {
          loadingLoginViaQuery.value = false;
        });
    };

    watch(
      queryAccessToken,
      (value) => {
        if (typeof value === 'string' && value && !token.value) {
          loginViaQuery(value);
        }
      },
      { immediate: true }
    );

    return {
      reloadPage,
      webimInitilized,
      isNeedUpdate,
    };
  },
});
</script>
