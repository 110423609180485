import { AxiosResponse } from 'axios';

import { errorCodes } from '@/libs/Http/errorCodes';
import { ServerPayload } from '@/libs/Http/Response';
import { InterceptorFn } from '@/libs/Http/setupInterceptors';

import { NewAppVersionService } from './NewAppVersion.service';

export function deployInterceptor(
  newAppVersionService: NewAppVersionService
): InterceptorFn {
  return (interceptors) => {
    interceptors.response.use((response: AxiosResponse<any>) => {
      const payload = response.data;
      const isError =
        payload.statusInfo &&
        payload.statusInfo.status === errorCodes.ErrorStatus;

      if (!isError) {
        return response;
      }

      const messages = (payload as ServerPayload<any>).statusInfo.infoMessages;
      const isVersionError =
        !!messages &&
        messages.find(
          ({ code }) => code === errorCodes.ApplicationVersionError
        );

      if (isVersionError) {
        newAppVersionService.needNewVersion();
      }

      return response;
    });
  };
}
