import { RouteLocationNormalized } from 'vue-router';

export function appointmentDoctorsGuard(to: RouteLocationNormalized): boolean {
  const {
    activePatientId,
    specilizationTypeId,
    clinicId,
    locationId,
    referralId,
  } = to.query;

  if (!Number(activePatientId)) {
    return false;
  }

  if (!Number(specilizationTypeId) && !Number(referralId)) {
    return false;
  }

  if (!Number(clinicId)) {
    return false;
  }

  if (!Number(locationId)) {
    return false;
  }

  return true;
}
