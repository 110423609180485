import { inject, provide } from 'vue';

import { createUseRequest } from '@smartmed/http/useRequest';

type Key = 'kong' | 'gateway';

const toKey = (key: Key) => `_endpoints_${key}`;

type HttpInstance = ReturnType<
  typeof createUseRequest<any, { inferData: true }>
>;

export function provideHttpInstance(key: Key, instance: HttpInstance) {
  provide(toKey(key), instance);
}

export function useHttp(key: Key): HttpInstance {
  const instance = inject<HttpInstance>(toKey(key));

  if (!instance) {
    throw new Error(`Not found http instance for ${key}`);
  }

  return instance;
}
